@mixin arrow {
  &::after {
    content: "";
    display: inline-block;
    width: px(14);
    height: px(14);
    vertical-align: middle;

    border-bottom: 3px solid var(--grayLight);
    border-right: 3px solid var(--grayLight);
    border-radius: 1px 2px 2px 2px/ 1px 2px 2px 2px;

    transition: all 0.2s linear;
    transform: rotateZ(-45deg);

    position: relative;
    z-index: 2;
    &:hover {
      &::after {
        border-bottom-color: var(--redMedium);
        border-right-color: var(--redMedium);
      }
    }

    @content;
  }
}
