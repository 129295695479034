.pagination {
  margin: 0 auto;
  margin-bottom: 40px;
  width: fit-content;

  &__btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    min-width: unset;
    width: 50px;
    height: 50px;
    font-size: 20px;
    border: 1px solid var(--redMedium);
    background-color: var(--grayLight);

    @include lg {
      width: 48px;
      height: 48px;
      font-size: px(18);
    }
    @include sm {
      width: 40px;
      height: 40px;
      font-size: px(16);
    }

    &:disabled {
      pointer-events: none;
    }

    &:not(:last-child) {
      margin-right: 8px;

      @include lg {
        margin-right: 6px;
      }
      @include sm {
        margin-right: 3px;
      }
    }

    &--prev {
      &:hover::after,
      &:focus::after {
        border-bottom-color: transparent;
        border-right-color: transparent;
        border-top-color: var(--grayLight);
        border-left-color: var(--grayLight);
      }

      &::after {
        border-bottom-color: transparent;
        border-right-color: transparent;
        border-top: 3px solid var(--redMedium);
        border-left: 3px solid var(--redMedium);
      }
    }

    &--next {
      &:hover::after,
      &:focus::after {
        border-bottom-color: var(--grayLight);
        border-right-color: var(--grayLight);
      }

      &::after {
        border-bottom: 3px solid var(--redMedium);
        border-right: 3px solid var(--redMedium);
      }
    }

    &--active {
      color: var(--grayLight);
      background-color: var(--redMedium);
      pointer-events: none;
    }

    &--prev,
    &--next {
      @include sm {
        &::after {
          width: 12px;
          height: 12px;
          border-width: 2px;
        }
      }
    }

    &--disabled {
      opacity: 0.2;
      pointer-events: none;
    }
  }
}
