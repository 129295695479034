.btn {
  display: inline-block;
  padding: px(18) px(40);
  border: px(1) solid var(--redMedium);
  background-color: var(--redMedium);
  cursor: pointer;

  font-family: "Montserrat", sans-serif;
  font-size: px(20);
  color: var(--grayLight);
  font-weight: 700;
  line-height: 1.5;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  vertical-align: top;

  transition: all 0.2s linear;

  position: relative;

  @include lg {
    font-size: px(18);
    padding: px(16) px(30);
  }

  @include xs {
    font-size: px(16);
    padding: px(16) px(30);
  }

  span {
    position: relative;
    z-index: 2;
  }

  &::before {
    content: "";
    display: block;
    width: 0;
    height: 100%;
    background-color: var(--baseWhite);

    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    transition: all 0.2s linear;
  }

  &:hover {
    color: var(--redMedium);
    border-color: rgba($redMedium, 0.8);

    &::before {
      width: 100%;
    }
  }

  &:active {
    &::before {
      background-color: rgba(($grayLight), 0.8);
    }
    color: rgba($redMedium, 0.8);
  }

  &:focus {
    background-color: rgba($grayLight, 0.8);
    color: var(--redMedium);
  }

  &--gray {
    background-color: var(--grayLight);
    color: var(--redMedium);
    border: 0;

    &::before {
      background-color: var(--redDark);
    }

    &:active {
      &::before {
        background-color: var(--redDark);
      }

      background-color: var(--redDark);
      color: rgba(var(--gray-light-rgb, 0.5));
    }

    &:focus {
      background-color: var(--redDark);
      color: rgba(var(--gray-light-rgb, 0.5));
    }

    @include xl {
      font-size: px(14);
    }

    @include lg {
      padding: px(15) px(30);

      border: px(1) solid var(--redDark);
    }

    &:hover {
      background-color: var(--redMedium);
      color: var(--grayLight);
    }
  }

  &--arrow {
    @include arrow;

    &:hover {
      &::after {
        border-bottom-color: var(--redMedium);
        border-right-color: var(--redMedium);
      }
    }

    &:active {
      &::after {
        border-bottom-color: rgba($redMedium, 0.8);
        border-right-color: rgba($redMedium, 0.8);
      }
    }

    &:focus {
      &::after {
        border-bottom-color: rgba($redMedium, 0.8);
        border-right-color: rgba($redMedium, 0.8);
      }
    }
  }

  &--sm {
    display: block;
    min-width: px(60);
    height: px(60);
    padding: 0;

    text-align: center;

    background-color: var(--redMedium);
    border: px(1) solid var(--redMedium);
    cursor: pointer;

    @include md {
      width: px(50);
      min-width: px(50);
      height: px(50);
    }

    &:hover {
      background-color: transparent;
      &::after {
        border-right-color: var(--redMedium);
        border-bottom-color: var(--redMedium);
      }
    }

    &:focus {
      &::after {
        border-right-color: var(--redMedium);
        border-bottom-color: var(--redMedium);
      }
    }

    &:active {
      border-width: px(2);
    }

    @include arrow {
      position: absolute;
      top: 50%;
      left: 50%;

      transform: translate3d(-50%, -50%, 0) rotate(-45deg);
    }
  }

  &--rotate {
    &::after {
      transform: translate3d(-50%, -50%, 0) rotate(-45deg);
    }
  }

  &--down {
    &:hover {
      &::before {
        height: 100%;
      }
    }
    &::before {
      width: 100%;
      height: 0;
    }
    &::after {
      transform: translate3d(-50%, -50%, 0) rotate(45deg);
    }
  }

  &--up {
    width: px(60);
    height: px(60);
    padding: 0;
    background-color: rgba(var(--baseWhite-rgb), 30%);
    background-image: url(../svg/arrow-up.svg);
    background-repeat: no-repeat;
    background-position: center;
    border: px(1) solid var(--redMedium);

    position: fixed;
    bottom: px(47);
    right: px(47);
    z-index: 1000;

    transform: translateY(200%);

    &::before {
      display: none;
    }

    &:hover {
      border-color: var(--redMedium);
    }

    &.white {
      background-color: var(--baseWhite);
    }

    &.show-btn {
      transform: translateY(0);
    }

    @include xs {
      width: px(50);
      height: px(50);
      right: px(20);
    }
  }
}
