.subscribe {
  z-index: 1;
  padding-bottom: px(70);

  @include sm {
    padding-bottom: px(50);
  }

  &__form {
    display: flex;
    justify-content: center;

    @include lg {
      flex-direction: column;
      align-items: center;
    }

    .form__row {
      display: -webkit-box;
      width: calc(100% + px(20));
      max-width: px(1051);
      margin-inline: px(-10);

      @include lg {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        max-width: px(600);
        padding: 0;
      }
    }

    .form__col {
      max-width: px(400);
      padding: 0 px(10);

      margin-bottom: px(30);

      @include lg {
        width: 100%;
        max-width: none;
        padding: 0 0 px(20) 0;
        margin: 0;
      }
    }

    .form-field {
      position: relative;

      &.invalid {
        .form-field__input {
          color: var(--redLight);
        }
      }
    }

    .form-field__input {
      font-family: "Montserrat", sans-serif;
      font-size: px(16);
      color: var(--blackLight);
      font-weight: 500;
      line-height: 1.5;

      padding: px(21) px(20);
      background-color: var(--grayLight);
      border: 0.5px solid transparent;
      border-radius: 0;
      outline: none;

      transition: border 0.2s linear;

      @include xs {
        padding: px(16) px(18);
      }

      &:focus {
        border-color: var((--redMedium));
      }

      &::placeholder {
        color: var(--grayExtraBold);
      }
    }
  }

  .form-field__error {
    position: absolute;
    top: 100%;
    margin-bottom: px(20);

    font-size: px(16);

    @include lg {
      bottom: px(-25);
    }
  }

  .btn {
    margin-left: px(10);
    line-height: 1.45;

    @include lg {
      margin-left: 0;
    }
  }

  &__bg-photo {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    width: 100%;
    height: 100%;

    &__picture__image {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__bg-photo__picture__img {
    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: center;
  }
}
