.calculation-page {
  background-color: var(--gray);
}

.calculation {
  width: 100%;
  max-width: px(1060);
  margin: 0 auto;

  &__text {
    font-family: "Montserrat", sans-serif;
    font-size: px(18);
    color: var(--blackLight);
    line-height: 1.5;

    margin-bottom: px(60);

    @include xl {
      font-size: px(16);
    }
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
    margin-inline: px(-10);

    margin-bottom: px(60);
  }

  &__col {
    display: flex;
    width: 50%;
    padding: 0 px(10);

    @include md {
      width: 100%;
      margin-bottom: px(30);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;

    padding: px(40);
    background-color: var(--baseWhite);
    box-shadow: 0px px(6) px(16) 0px rgba(var(--gray-medium-rgb), 0.07);

    @include xs {
      padding: px(20);
    }
  }

  &__item-title {
    font-family: "Montserrat", sans-serif;
    font-size: px(24);
    color: var(--blackLight);
    font-weight: 600;
    text-transform: uppercase;

    margin-bottom: px(40);

    @include xl {
      font-size: px(20);
    }

    @include xs {
      font-size: px(18);
    }

    i {
      font-style: normal;
      color: var(--redMedium);
    }
  }

  &__item-label {
    display: block;

    font-family: "Montserrat", sans-serif;
    font-size: px(16);
    color: var(--blackLight);
    line-height: 1.5;

    margin-bottom: px(8);

    @include xs {
      font-size: px(14);
    }
  }

  &__item-group {
    margin-bottom: px(40);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item-option__item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: px(20);
  }

  &__item-option__item-name {
    font-family: "Montserrat", sans-serif;
    font-size: px(16);
    color: var(--blackLight);
    line-height: 1.5;

    @include xs {
      font-size: px(14);
    }
  }

  &__item-option__item-count {
    font-family: "Montserrat", sans-serif;
    font-size: px(20);
    color: var(--blackLight);
    font-weight: 500;
    line-height: 1.3;

    @include xs {
      font-size: px(16);
    }
  }

  &__item-footer {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    padding-top: px(20);

    border-top: px(1) solid var(--gray);
  }

  &__item-footer__title {
    font-family: "Montserrat", sans-serif;
    font-size: px(16);
    color: var(--redMedium);
    font-weight: 600;
    line-height: 1.5;

    @include xs {
      font-size: px(14);
    }
  }

  &__item-footer__total {
    font-family: "Montserrat", sans-serif;
    font-size: px(20);
    color: var(--blackLight);
    font-weight: 600;
    line-height: 1.3;

    @include xs {
      font-size: px(18);
    }
  }

  &__footer {
    text-align: center;
    padding-bottom: px(70);

    @include sm {
      padding-bottom: px(40);
    }
  }

  &__footer-title {
    font-family: "Montserrat", sans-serif;
    font-size: px(20);
    color: var(--blackLight);
    font-weight: 600;
    line-height: 1.3;

    margin-bottom: px(20);

    @include xs {
      font-size: px(18);
    }
  }
}
