.favor {
  width: 100%;
  max-width: px(1060);
  display: inline-block;
  margin: 0 auto;

  padding-bottom: px(20);
  padding-top: px(20);

  &:first-child {
    padding-top: 0;
  }

  &--border {
    border-bottom: 0.5px solid var(--gray);
  }

  p,
  h6 {
    font-family: "Montserrat", sans-serif;
    font-size: px(18);
    color: var(--blackLight);
    font-weight: 400;
    line-height: 1.5;

    margin-bottom: px(20);

    @include sm {
      font-size: px(16);
    }
  }

  p:last-child {
    margin-bottom: 0;
  }

  &--picture {
    @include lg {
      text-align: center;

      a {
        display: inline-block;
      }
    }

    ul {
      text-align: left;
    }
  }

  b {
    font-weight: 600;
    color: var(--blackLight);
  }

  & + .btn {
    margin: px(40) 0;
  }

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  ul {
    width: 100%;
    max-width: px(737);
    column-count: 2;
    column-gap: unset;

    @include lg {
      max-width: px(511);
      margin: 0 auto;
      column-gap: px(50);
    }

    @include xs {
      column-count: 1;
      column-gap: 0;
      max-width: px(380);
    }
  }

  &--list {
    ul {
      width: 100%;
      max-width: none;
      column-count: auto;
      column-gap: unset;

      margin-bottom: px(20);
    }

    h6 {
      margin-bottom: px(12);
    }
  }

  li {
    font-family: "Montserrat", sans-serif;
    font-size: px(18);
    color: var(--blackLight);
    line-height: 1.5;

    position: relative;

    padding-left: px(25);
    margin-bottom: px(12);

    @include sm {
      font-size: px(16);
    }

    &::before {
      content: "";
      display: inline-block;
      width: px(13);
      height: px(13);

      position: absolute;
      top: px(5);
      left: 0;
      z-index: 1;

      background-color: var(--redMedium);
    }
  }

  img {
    float: right;

    max-width: px(282);
    max-height: px(340);

    @include lg {
      display: block;
      margin: 0 auto px(40);
      float: none;

      max-width: px(200);
    }
  }

  h3 {
    font-family: "Montserrat", sans-serif;
    font-size: px(24);
    color: var(--blackLight);
    font-weight: 600;
    line-height: 1.5;
    text-transform: uppercase;
    text-align: center;

    margin-top: px(40);
    margin-bottom: px(40);
  }

  i {
    color: var(--redMedium);
    font-style: normal;
  }

  &--table {
    margin-inline: px(-20);
    padding-bottom: px(60);
    div {
      display: flex;
      flex-wrap: wrap;
      margin-inline: px(-20);

      @include lg {
        flex-direction: column;
      }
    }
  }

  table {
    width: 33.33333%;
    display: inline-block;
    padding: 0 px(20);
    margin-bottom: px(40);

    font-family: "Montserrat", sans-serif;

    border-right: px(1) solid var(--gray);

    &:last-child {
      border-right-color: transparent;
    }

    @include lg {
      width: 100%;
      max-width: px(400);
      margin: 0 auto px(40);
      border-right: none;
      border-bottom: px(1) solid var(--gray);
      padding: 0 px(20) px(20);

      &:last-child {
        border-bottom: none;
      }
    }
  }

  thead {
    display: block;
    margin-bottom: px(20);
  }

  tbody {
    display: block;
  }

  tr {
    display: flex;
    justify-content: space-between;
    margin-bottom: px(12);

    th {
      font-size: px(18);
      color: var(--blackLight);
      font-weight: 600;
      line-height: 1.5;
    }

    th:last-child {
      font-size: px(18);
      color: var(--grayDarken);
      font-weight: 600;
      line-height: 1.5;
    }

    td {
      font-size: px(18);
      color: var(--blackLight);
      font-weight: 400;
      line-height: 1.5;

      @include sm {
        font-size: px(16);
      }
    }

    td:last-child {
      font-size: px(20);
      color: var(--blackLight);
      font-weight: 500;
      line-height: 1.3;

      @include sm {
        font-size: px(18);
      }
    }
  }

  &--gray {
    max-width: none;
    background-color: var(--grayMedium);

    padding: px(60) 0;
    position: relative;
    z-index: 1;

    &::after,
    &::before {
      content: "";
      position: absolute;
      width: 100vw;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;

      background-color: var(--grayMedium);
    }

    &::before {
      left: -100vw;
    }

    div {
      width: 100%;
      max-width: px(1060);
      margin: 0 auto;
    }

    ul {
      column-gap: px(106);
      width: 100%;
      max-width: px(1060);

      li {
        font-size: px(20);
        font-weight: 500;
        line-height: 1.3;

        @include sm {
          font-size: px(18);
        }
      }

      @include xs {
        max-width: px(350);
        column-gap: 0;
      }
    }
  }

  h4 {
    font-family: "Montserrat", sans-serif;
    font-size: px(24);
    color: var(--blackLight);
    font-weight: 600;
    line-height: 1.5;
    text-align: center;

    max-width: px(962);

    margin: 0 auto px(40);

    @include md {
      font-size: px(20);
    }
  }

  &--footer {
    padding: px(80) 0 px(60);

    h3 {
      width: 100%;
      max-width: px(833);

      font-family: "Montserrat", sans-serif;
      font-size: 24px;
      color: var(--blackLight);
      font-weight: 600;
      line-height: 1.5;
      text-align: center;
      text-transform: uppercase;

      margin: 0 auto;

      @include md {
        font-size: px(20);
      }

      @include xs {
        font-size: px(18);
      }
    }

    b {
      color: var(--redMedium);
    }
  }
}

h2 {
  @extend .section__title;
}
