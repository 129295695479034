.icon {
  transition: all 0.3s ease;

  &-arrow-select {
    width: px(10);
    min-width: px(10);
    height: px(10);
  }

  &-user,
  &-password,
  &-email {
    width: px(20);
    min-width: px(20);
    height: px(20);
  }

  &-write,
  &-apostille,
  &-another {
    width: px(100);
    height: px(100);
    fill: var(--redMedium);

    @include xl {
      width: px(80);
      height: px(80);
    }
  }

  &-location,
  &-time,
  &-phone,
  &-mail,
  &-location-contacts {
    width: px(24);
    height: px(24);

    fill: var(--blueBlack);
    stroke: var(--blackLight);
  }

  &-telegram {
    width: px(44);
    height: px(37);
    fill: var(--blackLight);
  }

  &-contact {
    width: px(28);
    height: px(28);

    fill: var(--baseWhite);
  }

  &-arrow-select {
    background-color: transparent;
    fill: var(--redMedium);

    width: px(30);
    height: px(30);
    background: var(--redMedium);
    mask: url(../svg/arrow-select.svg) center center/contain no-repeat;
  }

  &-attached {
    width: px(20);
    height: px(20);

    fill: var(--grayExtraBold);
  }

  &-close {
    width: px(36);
    height: px(36);

    fill: var(--redMedium);
    stroke: var(--redMedium);
  }
}
