.form-field {
  width: 100%;

  &.invalid {
    .form-field {
      &__textarea-container,
      &__input-container {
        .icon {
          fill: red;
        }
      }

      &__textarea,
      &__input {
        border-color: red;
      }

      &__error {
        display: block;
      }
    }
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    display: block;
    margin-bottom: px(5);
  }

  &__optional {
    display: block;
    margin-left: px(10);
    color: grey;
  }

  &__textarea-container,
  &__input-container {
    width: 100%;
    position: relative;
    transition: all 0.3s ease;
  }

  &__input-container {
    width: 100%;
    margin-bottom: px(5);
    position: relative;

    .icon {
      position: absolute;
      top: 50%;
      left: px(10);
      transform: translateY(-50%);
    }

    .icon + .form-field__input {
      padding-left: px(35);
    }
  }

  &__textarea-container {
    .icon {
      position: absolute;
      top: px(10);
      left: px(10);
    }

    .icon + .form-field__textarea {
      padding-left: px(35);
    }
  }

  &__textarea,
  &__input {
    width: 100%;
    padding: px(10) px(20);
    text-align: start;
    border: px(1) solid #000;
  }

  &__input {
    &::placeholder {
      color: grey;
    }
  }

  &__textarea {
    min-height: px(106);
  }

  &__error,
  &__message {
    font-family: 'Montserrat', sans-serif;
    font-size: px(16);
    line-height: 1.5;
    
    display: none;
    margin-top: px(5);
    width: 100%;

    @include sm {
      font-size: px(14);
    }
  }

  &__error {
    color: red;
  }

  &__row {
    display: flex;
    width: calc(100% + 20px);
    margin-inline: -10px;
  }

  &__col {
    padding-inline: 10px;
  }
}
