.passport {
  width: 100%;
  max-width: px(1060);
  margin: px(60) auto;

  &--sm {
    margin: px(40) auto px(60);
  }

  &--gray {
    max-width: px(1160);
    padding: px(90) 0;
    background-color: var(--grayMedium);

    position: relative;
    z-index: 1;

    &::before,
    &::after {
      content: "";
      display: block;
      width: 100vw;
      height: 100%;
      background-color: inherit;

      position: absolute;
      top: 0;
      left: -100vw;
      z-index: 1;
    }

    &::after {
      left: 100%;
    }
  }

  p {
    font-family: "Montserrat", sans-serif;
    font-size: px(18);
    color: var(--blackLight);
    line-height: 1.5;

    & + p {
      margin-top: px(20);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  b {
    color: var(--blackLight);
    font-weight: 600;
  }

  h3 {
    font-family: "Montserrat", sans-serif;
    font-size: px(24);
    color: var(--blackLight);
    font-weight: 600;
    line-height: 1.5;
    text-align: center;
    text-transform: uppercase;

    margin-bottom: px(40);
  }

  i {
    font-style: normal;
    color: var(--redMedium);
  }

  ul {
    column-count: 2;
    column-gap: px(22);
  }

  li {
    font-family: "Montserrat", sans-serif;
    font-size: px(18);
    color: var(--blackLight);
    line-height: 1.5;

    padding-left: px(32);
    margin-bottom: px(20);

    position: relative;

    &::before {
      content: "";
      display: inline-block;
      width: px(13);
      height: px(13);
      background-color: var(--redMedium);

      position: absolute;
      top: px(5);
      left: 0;
      z-index: 1;
    }
  }

  div {
    margin: px(40) 0;

    li {
      margin-bottom: px(12);
    }
  }

  h4 {
    font-family: "Montserrat", sans-serif;
    font-size: px(24);
    color: var(--blackLight);
    font-weight: 600;
    line-height: 1.5;
    text-align: center;

    width: 100%;
    max-width: px(1160);
    margin: 0 auto px(20);
  }

  h5 {
    font-family: "Montserrat", sans-serif;
    font-size: px(18);
    color: var(--blackLight);
    line-height: 1.5;

    margin-bottom: px(20);
  }
}
