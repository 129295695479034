.radio-group {
  &__title {
    font-size: px(18);
    margin-bottom: px(15);
    transition: all .3s ease;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    width: fit-content;
    cursor: pointer;

    &:hover {
      .radio-group__item-name {
        color: #ca66fb;
      }
    }

    &:not(:last-child) {
      margin-bottom: px(10);
    }

    .radio {
      margin-right: px(10);
    }
  }

  &__item-name {
    transition: all .3s ease;
  }
}
