.article {
  &--gray {
    padding: px(60) 0 px(50);
    background-color: var(--grayMedium);
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
    margin-inline: px(-10);

    @include lg {
      flex-direction: column;
      margin-inline: 0;
    }
  }

  &__heading {
    font-family: "Montserrat", sans-serif;
    font-size: px(24);
    color: var(--blackLight);
    font-weight: 600;
    line-height: 1.5;
    text-align: center;

    margin-bottom: px(30);
  }

  &__col {
    display: flex;
    width: 33.33333%;
    padding: px(10);

    @include lg {
      width: 100%;
      max-width: px(500);
      margin: 0 auto;
      padding: px(10) 0;
    }

    @include xs {
      text-align: center;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    padding: px(40);
    box-shadow: 0px 4px 54px 0px rgba(var(--gray-rgb), 0.2);

    border: px(1) solid var(--gray);
    background-color: var(--grayLight);

    @include xl {
      padding: px(30);
    }

    @include xs {
      padding: px(15);
    }
  }

  &__header {
    height: px(334);
    margin-bottom: px(20);
  }

  &__photo__image {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__photo__img {
    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: top center;
  }

  &__content {
    margin-bottom: px(58);
  }

  &__content-header {
    &::after {
      content: "";
      display: block;
      width: px(80);
      height: px(2);
      background-color: var(--redMedium);
      margin: px(20) 0;

      @include xs {
        margin: px(20) auto;
      }
    }
  }

  &__title {
    font-family: "Montserrat", sans-serif;
    font-size: px(24);
    color: var(--blackLight);
    font-weight: 700;
    line-height: 1.5;

    @include hide-text {
      line-clamp: 2;
      -webkit-line-clamp: 2;
    }

    @include xl {
      font-size: px(20);
    }

    @include sm {
      font-size: px(18);
    }

    a {
      color: inherit;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__text {
    font-family: "Montserrat", sans-serif;
    font-size: px(18);
    color: var(--blackLight);
    line-height: 1.5;

    margin-bottom: px(20);

    @include hide-text {
      line-clamp: 2;
      -webkit-line-clamp: 2;
    }

    @include xl {
      font-size: px(16);
    }
  }

  &__category {
    display: flex;
    flex-wrap: wrap;

    @include xs {
      justify-content: center;
    }
  }

  &__category-item {
    display: inline-block;
    padding: px(8) px(20);

    font-family: "Montserrat", sans-serif;
    font-size: px(12);
    font-weight: 500;
    text-transform: uppercase;

    transition: opacity 0.2s linear;

    @include xl {
      font-size: px(10);
    }

    & + .article__category-item {
      margin-left: px(12);
    }

    &--green {
      background-color: rgba(var(--green-rgb), 0.1);
      color: var(--green);
    }

    &--yellow {
      background-color: rgba(var(--yellow-rgb), 0.1);
      color: var(--yellow);
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &__footer {
    margin-top: auto;
  }

  &--btn {
    padding: px(15) px(20);
    text-transform: none;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}
