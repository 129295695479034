.benefits {
  display: flex;
  flex-wrap: wrap;

  &__item {
    display: flex;
    flex-direction: column;
    width: 25%;
    padding: 0 px(40);

    margin-bottom: px(70);

    border-right: px(1) solid var(--gray);

    @include xl {
      width: 50%;

      &:nth-child(even) {
        border-right-color: transparent;
      }
    }

    @include lg {
      width: 100%;
      max-width: px(600);
      padding: 0;

      margin: 0 auto px(70);
      border: none;
    }

    &:last-child {
      border-right-color: transparent;
    }
  }

  &__header {
    margin-bottom: px(20);
  }

  &__header-photo {
    height: px(240);

    @include sm {
      height: auto;
    }
  }

  &__header-photo__picture__image {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__header-photo__picture__img {
    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__content-title {
    font-family: "Montserrat", sans-serif;
    font-size: px(20);
    color: var((--blackLight));
    font-weight: 600;
    line-height: 1.3;
    text-align: center;

    margin-bottom: px(20);
    padding-bottom: px(20);

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    z-index: 1;

    span {
      min-height: px(52);
    }

    @include sm {
      font-size: px(18);
    }

    @include xs {
      font-size: px(16);
    }

    &::after {
      content: "";
      display: block;
      width: px(80);
      height: px(2);
      margin-top: px(20);

      position: absolute;
      bottom: 0;
      left: 50%;
      z-index: 1;

      transform: translateX(-50%);

      background-color: var(--redMedium);
    }
  }

  &__content-text {
    font-family: "Montserrat", sans-serif;
    font-size: px(18);
    color: var((--blackLight));

    margin-top: auto;

    p {
      margin-bottom: px(12);

      &:last-child {
        margin-bottom: 0;
      }
    }

    @include sm {
      font-size: px(16);
    }

    @include xs {
      font-size: px(14);
    }
  }
}
