.accordion {
  margin: 0 auto;

  &__item {
    &:not(:last-child) {
      margin-bottom: px(30);
    }

    &.active {
      .accordion__toggler {
        background-color: #000;
        color: #fff;
      }
    }
  }

  &__toggler {
    width: 100%;
    padding: px(10) px(20);
    text-align: center;
    border: px(1) solid black;
    transition: all 0.3s ease;
  }

  &__content {
    display: none;
    padding: px(30) px(15);
    border-bottom: px(1) solid black;
  }
}
