.contents {
  width: 100%;
  max-width: px(1060);

  margin: px(60) auto;

  &--width {
    width: 100%;
    max-width: px(1600);

    margin: px(60) auto;

    @include md {
      margin: px(40) auto;
    }
  }

  @include md {
    margin: px(40) auto;
  }

  &--border {
    border-bottom: px(1) solid var(--gray);
  }

  &--border__top {
    border-top: px(1) solid var(--gray);
  }

  &--medium {
    margin: px(40) auto;
  }

  &--sm {
    margin: 0 auto;
    padding: 0 0 px(20);
  }

  &--photo {
    display: flex;
    justify-content: space-between;

    padding-bottom: px(20);
    padding-top: px(20);
    margin: 0 auto;

    @include sm {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    div {
      width: 71.8%;

      @include sm {
        width: 100%;
        order: 2;
      }

      @include sm {
        margin: 0 auto px(30);
        height: auto;
      }

      & + a {
        margin-left: px(20);

        @include sm {
          margin-left: 0;
        }
      }

      p:first-of-type,
      p:last-of-type {
        margin: 0 0 px(20);
      }
    }

    h6 {
      margin-bottom: px(20);

      @include xl {
        font-size: px(16);
      }

      @include sm {
        text-align: center;
      }
    }

    ul {
      width: 100%;
      max-width: 737px;

      // column-count: 2;
      column-gap: unset;

      @include lg {
        column-count: 1;
      }

      @include sm {
        max-width: px(300);
        margin: 0 auto;
      }
    }

    img {
      display: block;
      max-width: px(385);
      object-fit: contain;

      @include lg {
        width: 100%;
        height: auto;
      }

      @include sm {
        margin: 0 auto px(20);
        height: auto;
      }
    }

    a + div {
      margin-left: px(20);

      @include sm {
        margin-left: 0;
      }
    }
  }

  h4 {
    margin: 0 auto px(40);

    font-family: "Montserrat", sans-serif;
    font-size: px(24);
    color: var(--blackLight);
    font-weight: 600;
    line-height: 1.5;
    text-align: center;
    text-transform: uppercase;

    span {
      color: var(--redMedium);
    }

    @include xl {
      font-size: px(20);
    }

    @include md {
      font-size: px(18);
    }
  }

  h5 {
    font-family: "Montserrat", sans-serif;
    font-size: px(24);
    color: var(--blackLight);
    font-weight: 600;
    line-height: 1.5;
    text-align: center;

    margin: px(40) 0 px(20);
  }

  &--info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: px(252);
    padding: px(60) 0;
    margin: 0 auto;

    position: relative;

    background-color: var(--gray);

    @include md {
      padding: px(30) 0;
      min-height: px(200);
    }

    &::before,
    &::after {
      content: "";
      display: block;
      width: 100vw;
      height: 100%;
      background-color: inherit;

      position: absolute;
      top: 0;
      left: -100vw;
      z-index: 1;
    }

    &::after {
      left: 100%;
    }

    h4 {
      text-transform: none;
      max-width: px(989);
      margin: 0 auto;

      span {
        color: var(--redMedium);
      }

      & + ul {
        margin-top: px(40);
      }
    }

    ul {
      column-count: 2;
      column-gap: px(106);

      @include sm {
        column-gap: px(50);
      }

      @include xs {
        column-count: 1;
        column-gap: 0;
      }
    }

    li {
      a {
        font-size: px(20);
        font-weight: 500;
      }
    }
  }

  p {
    font-family: "Montserrat", sans-serif;
    font-size: px(18);
    color: var(--blackLight);

    margin: px(20) 0;

    @include xl {
      font-size: px(16);
    }

    & + h4 {
      padding-top: px(40);
    }

    &:last-of-type {
      margin: 0;
    }

    b {
      color: var(--blackLight);
      font-weight: 600;
    }

    a {
      color: inherit;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  i {
    font-style: normal;
    font-weight: 600;
    color: var(--redMedium);
  }

  &--table {
    table {
      width: 100%;
      max-width: px(520);
      margin: 0 auto px(40);
    }

    thead {
      display: block;
      margin-bottom: px(20);

      tr {
        display: flex;
        justify-content: space-between;
      }
    }

    th {
      &:first-child {
        font-family: "Montserrat", sans-serif;
        font-size: px(18);
        color: var(--blackLight);
        font-weight: 600;
        line-height: 1.5;
        text-align: left;

        padding-right: px(20);
      }

      &:last-child {
        font-family: "Montserrat", sans-serif;
        font-size: px(18);
        color: var(--grayDarken);
        font-weight: 600;
        line-height: 1.5;
        text-align: right;
      }
    }

    tr {
      display: flex;
      justify-content: space-between;

      margin-bottom: px(12);
    }

    td {
      &:first-child {
        font-family: "Montserrat", sans-serif;
        font-size: px(18);
        color: var(--blackLight);
        line-height: 1.5;

        padding-right: px(20);

        @include xl {
          font-size: px(16);
        }
      }

      &:last-child {
        font-family: "Montserrat", sans-serif;
        font-size: px(20);
        color: var(--blackLight);
        font-weight: 500;
        line-height: 1.3;
        text-align: right;

        @include xl {
          font-size: px(18);
        }
      }
    }
  }

  &--table__large {
    table {
      max-width: px(790);
    }

    td {
      max-width: px(655);
    }
  }

  &--row {
    div {
      display: flex;
      flex-wrap: wrap;
      margin-inline: px(-20);

      @include lg {
        flex-direction: column;
      }
    }

    table {
      width: 33.33333%;
      display: inline-block;
      padding: 0 px(20);
      margin-bottom: px(40);

      font-family: "Montserrat", sans-serif;

      border-right: px(1) solid var(--gray);

      &:last-child {
        border-right-color: transparent;
      }

      @include lg {
        width: 100%;
        max-width: px(400);
        margin: 0 auto px(40);
        border-right: none;
        border-bottom: px(1) solid var(--gray);
        padding: 0 px(20) px(20);

        &:last-child {
          border-bottom: none;
        }
      }
    }

    tbody {
      display: block;
    }
  }

  &--btn {
    text-align: center;
    margin: px(60) auto 0;

    p {
      margin: 0;

      a {
        text-decoration: none;
        margin-bottom: px(20);

        & + a {
          margin-left: px(17);
        }

        @include sm {
          display: block;
          width: 100%;
          max-width: px(400);
          margin: 0 auto px(20);

          & + a {
            margin: 0 auto px(20);
          }
        }

        color: var(--baseWhite);
        @extend .btn;
      }
    }

    a {
      font-family: "Montserrat", sans-serif;
      font-size: px(18);
      color: var(--redMedium);
      font-weight: 600;
      line-height: 1.5;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  li {
    font-family: "Montserrat", sans-serif;
    font-size: px(18);
    color: var(--blackLight);
    line-height: 1.5;

    position: relative;
    margin-bottom: px(12);
    padding-left: px(25);

    @include xl {
      font-size: px(16);
    }

    &::before {
      content: "";
      display: inline-block;
      width: px(13);
      height: px(13);
      background-color: var(--redMedium);

      position: absolute;
      top: px(5);
      left: 0;
      z-index: 1;
    }

    a {
      text-decoration: underline;

      @include xl {
        font-size: px(18);
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

  ol {
    li {
      padding-left: 0;
      list-style: decimal;
      list-style-position: inside;

      &::before {
        display: none;
      }
    }
  }

  &--col {
    ul {
      column-count: 2;
      margin-bottom: px(20);

      p {
        margin: 0;
        padding-bottom: px(20);
      }
    }

    @include xs {
      ul {
        column-count: 1;
        margin: 0 auto;
      }
    }

    div {
      margin-bottom: px(40);
    }
  }

  &--col__large,
  &--col__medium {
    ul {
      column-count: 4;
      margin-bottom: px(20);

      @include md {
        column-count: 3;
        max-width: px(450);
        margin: 0 auto;
      }

      @include xs {
        max-width: px(400);
        column-count: 2;
        margin: 0 auto;
      }

      p {
        margin: 0;
        padding-bottom: px(20);
      }
    }

    li {
      margin-bottom: px(20);
    }
  }

  &--col__medium {
    ul {
      column-count: 3;

      @include md {
        max-width: px(500);
        margin: 0 auto;
      }

      @include xs {
        max-width: px(400);
        column-count: 2;
        margin: 0 auto;
      }
    }
  }

  h6 {
    font-family: "Montserrat", sans-serif;
    font-size: px(18);
    color: var(--blackLight);
    font-weight: 600;
    line-height: 1.5;

    margin-bottom: px(12);

    @include xl {
      font-size: px(16);
    }
  }

  &--document,
  &--document__large {
    display: flex;
    flex-wrap: wrap;

    div {
      width: 33.33333%;
      padding: 0 px(39);
      margin: px(20) 0;

      border-right: px(1) solid var(--gray);

      text-align: center;

      @include lg {
        padding: 0 px(32);
      }

      @include md {
        width: 100%;
        padding: 0;
        margin-bottom: px(30);

        border-right: none;
      }

      &:last-child {
        border-right-color: transparent;
      }

      p {
        text-align: center;
      }

      a {
        display: block;
        max-width: px(353);
        height: px(320);

        @include md {
          height: auto;
        }

        margin: 0 auto px(20);
      }
    }

    img {
      max-width: px(242);
      max-height: px(320);

      object-fit: contain;
      object-position: center;
    }

    p {
      font-family: "Montserrat", sans-serif;
      font-size: px(20);
      color: var(--blackLight);
      font-weight: 400;
      line-height: 1.5;

      @include xl {
        font-size: px(18);
      }

      @include lg {
        margin-inline: 0;
        min-width: auto;
      }
    }
  }

  &--document__large {
    div {
      width: 50%;
      padding: 0 px(117);

      @include lg {
        padding: 0 px(39);
      }

      @include md {
        width: 100%;
        padding: 0;
        margin-bottom: px(30);

        border-right: none;
      }
    }
  }

  &--footer {
    margin: 0 auto;
    padding: px(80) 0 px(20);

    @include lg {
      padding: px(50) 0 px(20);
    }
  }

  h3 {
    width: 100%;
    max-width: px(885);
    margin: 0 auto px(30);
    font-family: "Montserrat", sans-serif;
    font-size: px(24);
    color: var(--blackLight);
    font-weight: 600;
    line-height: 1.5;
    text-align: center;
    text-transform: uppercase;

    span {
      color: var(--redMedium);
    }

    @include xl {
      font-size: px(20);
    }

    @include lg {
      font-size: px(18);
    }
  }

  &--picture {
    img {
      display: block;
      max-width: px(1600);
      max-height: px(520);

      object-fit: cover;
      object-position: center;

      margin: 0 auto;

      @include lg {
        width: 100%;
        height: auto;
      }
    }
  }

  &--title-center {
    h6 {
      text-align: center;
      margin-bottom: px(20);
    }
  }

  &--content-center {
    align-items: center;
  }
}
