.blog {
  &__nav {
    width: 100%;
    max-width: px(1191);

    margin: 0 auto px(20);
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
    margin-inline: px(-10);
    margin-bottom: px(40);
  }

  &__col {
    display: flex;
    width: 50%;
    padding: px(10);

    @include lg {
      width: 100%;
    }
  }

  &__item {
    display: flex;
    flex-wrap: wrap;
    padding: px(40);
    background-color: var(--baseWhite);
    box-shadow: 0px 6px 16px 0px rgba(var(--gray-medium-rgb), 0.07);

    border: px(1) solid var(--gray);

    @include xl {
      padding: px(30);
    }

    @include xs {
      padding: px(20);
    }
  }

  &__item-photo {
    width: 33.94%;

    @include sm {
      width: 100%;
      margin-bottom: px(20);
    }
  }

  &__item-photo__image {
    display: block;
    width: 100%;
    height: px(344);
  }

  &__item-photo__img {
    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: top center;
  }

  &__item-content {
    display: flex;
    flex-direction: column;
    width: 66.06%;
    padding-left: px(20);

    @include sm {
      width: 100%;
      padding-left: 0;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  &__item-content__header {
    &::after {
      content: "";
      display: block;
      width: px(80);
      height: px(2);
      background-color: var(--redMedium);

      margin: px(20) 0;

      @include sm {
        margin: px(20) auto;
      }

      @include xs {
        margin: px(15) auto;
      }
    }
  }

  &__item-content__title {
    font-family: "Montserrat", sans-serif;
    font-size: px(24);
    color: var(--blackLight);
    font-weight: 700;
    line-height: 1.5;

    @include xl {
      font-size: px(20);
    }

    @include sm {
      font-size: px(18);
    }

    @include hide-text {
      -webkit-line-clamp: 2;
      line-clamp: 2;
    }

    a {
      color: black;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__item-content__text {
    font-family: "Montserrat", sans-serif;
    font-size: px(18);
    color: var(--blackLight);
    line-height: 1.5;

    margin-bottom: px(28);

    @include xl {
      font-size: px(16);
    }

    @include sm {
      font-size: px(14);
    }

    @include hide-text;
  }

  &__item-content__footer {
    margin-top: auto;
  }

  &__item-content__category {
    display: flex;
    flex-wrap: wrap;

    margin-bottom: px(20);

    @include sm {
      justify-content: center;
    }
  }

  &__item-content__category-item {
    font-family: "Montserrat", sans-serif;
    font-size: px(12);
    font-weight: 500;
    line-height: 1.5;
    text-transform: uppercase;

    padding: px(8) px(20);

    transition: opacity 0.2s linear;

    @include xl {
      font-size: px(10);
    }

    &--green {
      background-color: rgba(var(--green-rgb), 0.1);
      color: var((--green));
    }

    &--yellow {
      background-color: rgba(var(--yellow-rgb), 0.1);
      color: var((--yellow));
    }

    &--orange {
      background-color: rgba(var(--orange-rgb), 0.1);
      color: var((--orange));
    }

    &--blue {
      background-color: rgba(var(--blue-rgb), 0.1);
      color: var((--blue));
    }

    &--pink {
      background-color: rgba(var(--pink-rgb), 0.1);
      color: var((--pink));
    }

    &--turquoise {
      background-color: rgba(var(--turquoise-rgb), 0.1);
      color: var((--turquoise));
    }

    & + .blog__item-content__category-item {
      margin-left: px(12);
    }

    a {
      display: block;
      width: 100%;
      height: 100%;
      color: inherit;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &--btn {
    padding: px(15) px(20);
    text-transform: none;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}
