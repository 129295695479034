/* width */
::-webkit-scrollbar {
  width: px(8);
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--gray);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--blackLight);
  border-radius: px(2) px(2);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
