html,
body {
  &.lock {
    overflow: hidden;
  }
}

body {
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  line-height: 1.3;
  font-size: px(18);
  color: var(--baseText);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  padding-right: var(--current-scrollbar-width);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul,
ol,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

a {
  color: black;
  text-decoration: none;
}

// a:hover {
//   text-decoration: none;
// }

button {
  padding: 0;
  background-color: initial;
  border: none;
  cursor: pointer;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

textarea {
  resize: none;
}

section {
  position: relative;
}

input,
select,
button,
textarea {
  font: inherit;
  color: inherit;
  outline: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

fieldset {
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-block-start: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-block-end: 0;
  min-inline-size: min-content;
  border-width: 0;
  border-style: none;
  border-color: transparent;
  border-image: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

.wrapper {
  min-height: 100vh;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  position: relative;

  overflow: hidden;
}

.content {
  flex: 1 0 auto;
  overflow: hidden;
  position: relative;
  z-index: 500;
}

.container {
  width: 100%;
  max-width: px(1630);
  margin: 0 auto;
  padding-right: px(15);
  padding-left: px(15);
}

.container-fluid {
  padding-right: px(15);
  padding-left: px(15);
  @include media-breakpoint-down(xs) {
    padding-left: pxs(15);
    padding-right: pxs(15);
  }
}

.row {
  display: flex;
  width: calc(100% + px(30));
  margin-inline: px(-15);
}

.col {
  padding-inline: px(15);
}

.important_none {
  display: none !important;
}

.custom-scrollbar {
  &::-webkit-scrollbar {
    width: px(4);
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #00000081;
  }
}
