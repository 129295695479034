.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: px(32);
  min-width: px(32);
  height: px(32);
  position: relative;
  transition: all 0.3s ease;
  cursor: pointer;

  @include xs {
    width: px(26);
    min-width: px(26);
    height: px(26);
  }

  &:hover {
    .checkbox__custom {
      opacity: 0.85;
    }
  }

  &__input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    appearance: none;
    cursor: pointer;

    &:focus {
      & + .checkbox__custom {
        border-color: var(--redMedium);
      }
    }
  }

  &__custom {
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
    pointer-events: none;
    border: px(1) solid var(--gray);
    position: relative;

    &::after {
      content: "\2713";
      display: none;
      width: px(20);
      height: px(20);

      font-size: px(25);
      color: var(--redMedium);

      position: absolute;
      top: px(-2);
      left: 50%;
      z-index: 1;

      transform: translate3d(-50%, 0, 0);

      @include xs {
        top: -25%;
      }
    }

    opacity: 1;
  }

  &__input:checked + &__custom {
    &::after {
      display: block;
    }
  }
}
