$iterate: 60;

.pt-header-height {
  padding-top: var(--header-height);
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.p-20 {
  padding: px(20) 0;
}

@for $i from 1 through $iterate {
  .p-#{$i} {
    padding: #{$i}px;
  }
}

@for $i from 1 through $iterate {
  .pt-#{$i} {
    padding-top: #{$i}px;
  }
}

@for $i from 1 through $iterate {
  .pe-#{$i} {
    padding-right: #{$i}px;
  }
}

@for $i from 1 through $iterate {
  .pb-#{$i} {
    padding-bottom: #{$i}px;
  }
}

@for $i from 1 through $iterate {
  .ps-#{$i} {
    padding-left: #{$i}px;
  }
}

@for $i from 1 through $iterate {
  .py-#{$i} {
    padding-block: #{$i}px;
  }
}

@for $i from 1 through $iterate {
  .px-#{$i} {
    padding-inline: #{$i}px;
  }
}
