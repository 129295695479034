.leaflet {
  &-control-attribution {
    display: none;
  }

  &-control-zoom {
    display: none;
  }

  &-bar a:not(:last-child) {
    border-bottom: px(1) solid var(--blackLight) !important ;
  }

  &-control-zoom {
    border: px(2) solid var(--blackLight) !important;
  }

  &-control-zoom-in {
    font-family: "Montserrat", sans-serif;
    font-size: px(30);
    color: var(--redMedium) !important;

    &:hover {
      background-color: var(--blackLight) !important;

      color: var(--baseWhite) !important;
    }
  }

  &-control-zoom-out {
    font-family: "Montserrat", sans-serif;
    font-size: px(30);
    color: var(--blackLight) !important;

    &:hover {
      background-color: var(--blackLight) !important;

      color: var(--baseWhite) !important;
    }
  }
}
