.form {
  &--calculate {
    margin: px(20) 0;

    &:last-child {
      margin: 0;
    }

    .form-field__col {
      display: block;
      font-family: "Montserrat", sans-serif;
      font-size: px(16);
      color: var(--blackLight);
      line-height: 1.5;

      cursor: pointer;
      padding-left: px(17);
      padding-right: 0;

      @include xs {
        font-size: px(14);
      }
    }

    .form-field__row {
      align-items: center;
    }
  }

  &__row {
    display: flex;
    width: calc(100% + px(30));
    margin-inline: px(-15);
    flex-wrap: wrap;
  }

  &__col {
    width: 50%;
    flex: 0 0 50%;
    padding: px(15);
    @include media-down(576) {
      width: 100%;
      flex: 0 0 100%;
    }
  }

  &__agree-privacy {
    display: flex;
    width: fit-content;
    align-items: center;

    .checkbox {
      margin-right: px(10);
    }

    a {
      text-decoration: underline;
      transition: all 0.3s ease;

      &:hover {
        color: #ca66fb;
      }
    }
  }
}
