.propositions {
  padding-bottom: px(70);

  &__inner {
    margin-inline: -10px;
    column-count: 2;
    column-gap: 0;

    @include lg {
      column-count: 1;
    }
  }

  &__col {
    padding: px(10);
    break-inside: avoid;

    &:nth-child(5) {
      break-after: column;

      @include lg {
        break-after: unset;
      }
    }
  }

  &__item {
    padding: px(40);
    background-color: var(--baseWhite);
    box-shadow: 0px 6px 18px 0px rgba(var(--gray-medium-rgb), 0.07);

    border: px(1) solid var(--gray);

    @include xl {
      padding: px(30);
    }

    @include sm {
      padding: px(15);
    }

    &.active {
      .propositions__item-header {
        padding-bottom: px(40);
        border-bottom: px(1) solid var(--gray);

        @include xl {
          padding-bottom: px(30);
        }

        @include md {
          padding-bottom: px(20);
        }

        @include sm {
          padding-bottom: px(15);
        }
      }

      .btn {
        background-color: transparent;

        &::after {
          border-right-color: var(--redMedium);
          border-bottom-color: var(--redMedium);

          transform: translate3d(-45%, -10%, 0) rotate(-135deg);
        }
      }
    }
  }

  &__item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    transition: padding 0.4s linear;
  }

  &__item-header-content {
    padding-right: px(40);

    @include xl {
      padding-right: px(20);
    }
  }

  &__item-content {
    padding-top: px(40);

    @include sm {
      padding-top: px(20);
    }

    &.hide {
      display: none;
    }
  }

  &__item-header-content__title {
    font-family: "Montserrat", sans-serif;
    font-size: px(24);
    color: var(--blackLight);
    font-weight: 700;
    line-height: 1.3;

    margin-bottom: px(12);

    @include hide-text {
      line-clamp: 2;
      -webkit-line-clamp: 2;
    }

    @include xl {
      font-size: px(20);
    }

    @include sm {
      font-size: px(18);
    }
  }

  &__item-header-content__text {
    font-family: "Montserrat", sans-serif;
    font-size: px(18);
    color: var(--blackLight);
    line-height: 1.5;

    @include hide-text {
      line-clamp: 2;
      -webkit-line-clamp: 2;
    }

    @include xs {
      font-size: px(14);

      @include hide-text {
        line-clamp: unset;
        -webkit-line-clamp: unset;
      }
    }
  }

  &__item-content__accordion-content {
    padding-top: px(12);
    padding-left: px(25);

    @include sm {
      padding-left: px(15);
    }
  }

  &__item-content__accordion-content__list-item {
    display: flex;
    gap: px(20);
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: px(12);
    }
  }

  &__item-content__accordion-content__list-item__link {
    font-family: "Montserrat", sans-serif;
    font-size: px(18);
    color: var(--blackLight);
    line-height: 1.5;
    padding-right: px(10);

    max-width: px(500);

    &:hover {
      text-decoration: underline;
    }

    @include xl {
      font-size: px(16);
    }

    @include sm {
      font-size: px(14);
    }

    @include xs {
      font-size: px(12);
    }
  }

  &__item-content__accordion-content__list-item__price {
    font-family: "Montserrat", sans-serif;
    font-size: px(20);
    color: var(--blackLight);
    line-height: 1.3;
    text-align: right;
    min-width: 78px;
    text-wrap: nowrap;

    @include xl {
      font-size: px(16);
    }

    @include sm {
      font-size: px(14);
    }

    @include xs {
      font-size: px(12);
    }
  }
}
