.hero {
  padding-block: px(77);

  position: relative;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    color: var(--blackMedium);
    background-color: rgba(var(--baseBlack-rgb), 0.71);
  }

  @include sm {
    padding: px(70) 0 px(42);
  }

  &__inner {
    text-align: center;

    position: relative;
    z-index: 2;
  }

  &__title {
    font-family: "Montserrat", sans-serif;
    font-size: px(44);
    color: var(--grayLight);
    font-weight: 700;
    line-height: 1.5;
    text-align: center;
    text-wrap: balance;
    text-transform: uppercase;

    margin-bottom: px(60);

    @include xl {
      font-size: px(38);
    }

    @include md {
      font-size: px(33);
    }

    @include sm {
      font-size: px(28);
    }

    @include xs {
      font-size: px(24);
      margin-bottom: px(40);
    }
  }

  &__content {
    text-align: center;
    margin-bottom: px(60);

    @include xs {
      margin-bottom: px(40);
    }
  }

  &__content-text {
    font-family: "Montserrat", sans-serif;
    font-size: px(24);
    color: var(--grayLight);

    width: 100%;
    max-width: px(1060);
    margin: 0 auto;

    b {
      font-weight: 600;
    }

    p {
      margin-bottom: px(11);
    }

    @include md {
      font-size: px(22);
    }

    @include xs {
      font-size: px(16);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__content-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .btn {
      margin-bottom: px(20);
    }

    & .btn--gray {
      border: px(1) solid transparent;

      @include xl {
        font-size: px(20);
      }

      @include lg {
        font-size: px(18);
        padding: px(16) px(30);
      }

      @include sm {
        font-size: px(14);
      }

      &:hover {
        border: px(1) solid var(--redDark);
      }
    }
  }

  &__content-row__item {
    padding: 0 px(10);
  }

  &__statistic {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: px(980);
    margin: 0 auto;

    margin-top: px(40);

    @include sm {
      margin-top: px(60);
    }
  }

  &__statistic-item {
    padding: 0 px(40);
    margin-bottom: px(30);
    width: 25%;

    @include md {
      padding: 0 px(20);
      width: 50%;
    }

    @include xs {
      width: 100%;
      padding: 0;
    }
  }

  &__statistic-item__count {
    font-family: "Montserrat", sans-serif;
    font-size: px(44);
    color: var(--grayLight);
    font-weight: 700;
    line-height: 1.5;

    @include xl {
      font-size: px(38);
    }

    @include md {
      font-size: px(35);
    }
  }

  &__statistic-item__count__text {
    font-family: "Montserrat", sans-serif;
    font-size: px(20);
    color: var(--grayLight);
    line-height: 1.5;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    width: 100%;
    height: 100%;
  }

  &__bg-photo {
    width: 100%;
    height: 100%;

    &__picture__image {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__bg-photo__picture__img {
    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: center;
  }
}
