.fslightbox-slide-number-container {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: var(--redMedium) !important;
  font-size: 20px !important;
}

.fslightbox-toolbar-button svg {
  width: 30px !important;
  height: 30px !important;
}

.fslightbox-slide-btn-container {
  padding: 0 !important;
  @media (max-width: 576px) {
    display: none !important;
  }
}

.fslightbox-slide-btn-container-next {
  padding-right: 10px !important;
}

.fslightbox-slide-btn-container-previous {
  padding-left: 10px !important;
}
