.company {
  padding-bottom: px(70);
  position: relative;
  overflow: hidden;

  .swiper-wrapper {
    display: flex;
    align-items: center;
  }

  .swiper-pagination-bullet {
    width: px(12);
    height: px(12);
    background: var(--blackLight);
    opacity: 0.6;
  }

  .swiper-pagination-bullet-active {
    background: var(--redMedium);
    opacity: 1;
  }

  &__item {
    display: block;
    text-align: center;

    &__img {
      width: 100%;
      max-width: 192px;
      height: 100%;
      max-height: 86px;
      object-fit: contain;
    }
  }
}
