.section {
  padding: px(100) 0 px(30);

  @include xl {
    padding: px(80) 0 px(20);
  }

  @include md {
    padding: px(60) 0 px(20);
  }

  z-index: 100;

  &--gray {
    background-color: var(--gray);
  }

  &.section--subscribe {
    .section__header {
      margin: 0 auto px(60);
    }
  }

  &--subscribe {
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;

      pointer-events: none;

      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;

      color: var(--blackMedium);
      background-color: rgba(var(--baseBlack-rgb), 0.78);
    }
  }

  &__header {
    max-width: px(816);
    margin: 0 auto px(80);

    position: relative;
    z-index: 2;

    text-align: center;

    @include xl {
      margin: 0 auto px(60);
    }
  }

  &__title {
    font-family: "Montserrat", sans-serif;
    font-size: px(32);
    color: var(--blackLight);
    font-weight: 700;
    line-height: 1.5;
    text-transform: uppercase;

    @include xl {
      font-size: px(28);
    }

    @include lg {
      font-size: px(25);
    }

    @include md {
      font-size: px(22);
    }

    @include xs {
      font-size: px(20);
    }
  }

  &__subtitle {
    font-family: "Montserrat", sans-serif;
    font-size: px(20);
    color: var(--grayLight);
    line-height: 1.5;

    margin-top: px(40);
  }
}
