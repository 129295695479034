.tabs {
  &__head {
    margin-bottom: px(40);
  }

  &__head-row {
    display: flex;
    width: calc(100% + px(20));
    margin-inline: px(-10);
  }

  &__head-col {
    padding-inline: px(10);
  }

  &__btn {
    &.active {
      background-color: #000;
      color: #fff;
    }
  }

  &__item {
    display: none;
  }
}
