.payment {
  width: 100%;
  max-width: px(1060);
  margin: 0 auto;

  @include xs {
    text-align: center;
  }

  div {
    margin-bottom: px(60);
  }

  h3 {
    font-family: "Montserrat", sans-serif;
    font-size: px(24);
    color: var(--blackLight);
    font-weight: 600;
    line-height: 1.5;
    text-transform: uppercase;
    text-align: center;

    margin-bottom: px(40);

    @include xl {
      font-size: px(17);
    }

    @include sm {
      font-size: px(15);
    }
  }

  b {
    color: var(--redMedium);
  }

  ul {
    margin-bottom: px(20);
  }

  h6 {
    font-family: "Montserrat", sans-serif;
    font-size: px(20);
    color: var(--blackLight);
    font-weight: 600;
    line-height: 1.3;

    margin-bottom: px(12);

    @include xl {
      font-size: px(17);
    }

    @include sm {
      font-size: px(15);
    }
  }

  p {
    font-family: "Montserrat", sans-serif;
    font-size: px(18);
    color: var(--blackLight);
    line-height: 1.5;

    @include xl {
      font-size: px(16);
    }

    @include sm {
      font-size: px(14);
    }
  }

  li {
    display: flex;
    flex-direction: column;
    span {
      display: inline-block;
      width: px(13);
      height: px(13);
      background-color: var(--redMedium);

      margin-right: px(10);
      vertical-align: middle;
    }
  }

  &__benefits {
    width: 100%;
    max-width: px(1060);
    margin: 0 auto px(70);

    @include lg {
      margin: 0 auto px(30);
    }

    @include xs {
      margin: 0 auto;
    }
  }

  &__benefits-inner {
    display: flex;
    flex-wrap: wrap;
    margin-inline: px(-20);
  }

  &__benefits-item {
    width: 25%;
    padding: 0 px(60);
    margin-bottom: px(60);
    text-align: center;

    border-right: px(1) solid var(--gray);

    @include md {
      padding: 0 px(20);
    }

    @include sm {
      width: 50%;
    }

    & .icon {
      width: px(60);
      height: px(60);
    }

    &:last-child {
      border-right-color: transparent;
    }
  }

  &__benefits-header {
    margin-bottom: px(40);
  }

  &__benefits-text {
    font-family: "Montserrat", sans-serif;
    font-size: px(20);
    color: var(--blackLight);
    font-weight: 500;
    line-height: 1.5;

    width: 100%;
    max-width: px(175);
    margin: 0 auto;

    @include xl {
      font-size: px(18);
    }

    @include sm {
      font-size: px(16);
    }
  }
}

h2 {
  @extend .section__title;
}
