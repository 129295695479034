.header {
  width: 100%;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  background-color: var(--blackLight);
  transition: transform 0.5s ease;
  box-shadow: 0px 4px 55px 0px rgba(var(--gray-dark-rgb), 0.12);

  &__inner {
    padding: px(15) var(--current-scrollbar-width) px(15) 0;
  }

  &__subnav {
    background-color: var(--grayLight);
    padding: px(14) 0 px(16);

    @include xl {
      padding: px(10) 0 px(12);
    }

    @include lg {
      width: 100%;
      height: calc(100vh - var(--header-height));
      background-color: var(--baseWhite);
      background-size: cover;
      padding: px(14) 0 px(40);
      overflow-y: scroll;
      overflow-x: hidden;

      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      z-index: 99;

      transform: translateX(100%);
      transition: transform 0.3s ease-in-out;
    }

    &.show {
      transform: translateY(0);
    }

    & .container {
      @include lg {
        padding: 0;
      }
    }
  }

  &__row {
    display: flex;
    align-items: center;
  }

  &__burger {
    margin-left: auto;
    z-index: 1010;
    @include media-up(993) {
      display: none;
    }
  }
}
