@use "sass:math";

.response {
  $maxCards: 100;

  position: relative;
  z-index: 5;

  max-width: px(1060);
  max-height: px(300);
  margin: px(150) auto 0;

  padding-bottom: px(40);

  @include sm {
    max-height: px(250);
  }

  &__item {
    width: 100%;
    max-width: px(1060);
    max-height: px(400);
    padding: px(40);
    margin: 0 auto;
    // overflow-y: auto;

    background-color: var(--baseWhite);
    box-shadow: 0px 5.5px 31px 0px rgba(var(--gray-medium-rgb), 0.07);

    @include sm {
      padding: px(20);
    }

    &.hide-text .response__text {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -moz-box;
      -moz-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-clamp: 2;
      box-orient: vertical;
    }
  }

  &__item.hide {
    visibility: hidden;
  }

  &__item:not(.hide) {
    position: absolute;
    top: 0;
    left: 0;
    transition: all 1.3s cubic-bezier(0.18, 0.98, 0.45, 1);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.07);
  }

  &__item:not(.hide)[data-slide="0"] {
    transform: translate(0, 0) scale(1);
    z-index: 100;
    opacity: 1;
  }

  &__name {
    font-family: "Montserrat", sans-serif;
    font-size: px(20);
    color: var((--blackLight));
    font-weight: 600;
    line-height: 1.3;

    margin-bottom: px(20);

    @include md {
      font-size: px(18);
    }

    @include xs {
      margin-bottom: px(15);
    }
  }

  &__text {
    font-family: "Montserrat", sans-serif;
    font-size: px(18);
    color: var(--blackLight);
    line-height: 1.5;
    max-height: px(250);
    overflow-y: auto;

    margin-bottom: px(12);

    p {
      display: inline;
      margin-bottom: px(8);
    }

    p::after {
      content: " \A\A";
      white-space: pre;
    }
  }

  &__btn {
    font-family: "Montserrat", sans-serif;
    font-size: px(18);
    color: var(--redMedium);
    font-weight: 500;
    line-height: 2;

    cursor: pointer;

    transition: opacity 0.2s linear;

    &.active {
      color: var(--grayExtraBold);

      &:hover {
        opacity: 0.7;
      }
    }
  }

  @for $i from 1 through $maxCards {
    .response__item:not(.hide)[data-slide="#{$i}"] {
      transform: translate(0, #{$i * (-45px)}) scale(1 - (0.05 * $i));
      z-index: #{$maxCards - $i};
      opacity: #{0.9 - math.div($i, 10)};

      .response__name,
      .response__text,
      .response__btn {
        opacity: 0;
      }

      @if $i < 3 {
        .response__item:not(.hide)[data-slide="#{$i}"] {
          opacity: 0;
        }
      } @else {
        opacity: 0;
      }
    }
  }

  &__item:not(.hide)[data-slide="0"] {
    transition: transform 1.3s cubic-bezier(0.18, 0.98, 0.45, 1), opacity 0 linear;
  }

  & + .flex-center {
    padding-bottom: px(70);
  }
}
