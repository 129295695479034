.burger-btn {
  display: block;
  width: px(40);
  min-width: px(30);
  height: px(40);
  position: relative;
  border: px(2) solid var(--baseWhite);

  display: none;

  @include lg {
    display: block;
  }

  &.active {
    i {
      &:nth-child(1) {
        top: 50%;
        background-color: var(--baseBlack);
        transform: translate(-50%, -50%) rotate(-45deg);
      }

      &:nth-child(2) {
        width: 100%;
        height: 100%;
        border-radius: 0;
      }

      &:nth-child(3) {
        top: 50%;
        background-color: var(--baseBlack);
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }

  i {
    display: block;
    width: 70%;
    height: px(3);
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--baseWhite);
    transition: all 0.3s ease;

    border-radius: px(2) px(2);

    &:nth-child(1) {
      top: 25%;
      z-index: 2;
    }

    &:nth-child(2) {
      top: 50%;
      z-index: 1;
    }

    &:nth-child(3) {
      top: 75%;
      z-index: 2;
    }
  }
}
