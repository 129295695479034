.path {
  padding-top: px(40);

  &__link {
    font-family: "Montserrat", sans-serif;
    font-size: px(20);
    color: var(--blackLight);
    line-height: 1.5;

    @include xl {
      font-size: px(18);
    }

    @include sm {
      font-size: px(16);
    }

    &:last-child {
      color: var(--redMedium);
      font-weight: 500;
      pointer-events: none;

      &::after {
        display: none;
      }
    }

    &:hover {
      color: var(--redMedium);
    }

    @include arrow {
      width: px(8);
      height: px(8);
      border-bottom: px(1) solid var(--grayDarken);
      border-right: px(1) solid var(--grayDarken);
      border-radius: 0.5px 1px 1px 1px/0.5px 1px 1px 1px;

      margin: 0 px(20);

      @include lg {
        margin: 0 px(10);
      }
    }
  }
}
