@keyframes fade-down {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(px(20));
  }
}

@keyframes fade-on {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
