.contacts {
  width: 100%;
  max-width: px(1060);
  margin: 0 auto;

  &--gray {
    padding: px(60) 0;
    background-color: var(--gray);
    position: relative;

    &::after,
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;

      width: 100vw;
      height: 100%;
      background-color: inherit;
    }

    &::before {
      left: -100vw;
    }
  }

  &__header {
    width: 100%;
    max-width: px(900);
    margin: 0 auto px(80);

    @include lg {
      margin: 0 auto px(60);
    }
  }

  &__title,
  &__subtitle {
    font-family: "Montserrat", sans-serif;
    font-size: px(24);
    color: var(--blackLight);
    font-weight: 400;
    line-height: 1.5;
    text-align: center;

    @include xl {
      font-size: px(20);
    }

    @include sm {
      font-size: px(18);
    }
  }

  &__title {
    font-weight: 600;
  }

  i {
    font-style: normal;
    color: var(--redMedium);
  }

  &__address {
    display: flex;
    flex-wrap: wrap;
  }

  &__address-item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    @include sm {
      align-items: center;
    }
  }

  &__address-item__info {
    width: 38.21%;
    padding-right: px(20);
    margin-bottom: px(40);

    @include sm {
      padding-right: px(27);
    }

    @include xs {
      width: 100%;
      margin-bottom: px(30);
      padding-right: 0;
    }
  }

  &__address-item__info-title {
    font-family: "Montserrat", sans-serif;
    font-size: px(20);
    color: var(--redMedium);
    font-weight: 500;
    line-height: 1.5;
    text-transform: uppercase;

    margin-bottom: px(20);

    @include xs {
      text-align: center;
    }
  }

  &__address-item__info-list__item {
    display: flex;
    align-items: center;

    margin-bottom: px(20);

    @include xs {
      justify-content: center;
    }

    .icon {
      stroke: var(--baseWhite);
    }
  }

  &__address-item__info-list__item-link {
    font-family: "Montserrat", sans-serif;
    font-size: px(18);
    color: var(--blackLight);
    margin-left: px(12);
    line-height: 1.5;

    @include md {
      font-size: px(16);
    }

    &:hover {
      text-decoration: underline;
    }

    &--time {
      &:hover {
        text-decoration: none;
      }
    }

    &--mail {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__address-item__location {
    width: 61.79%;
    margin-bottom: px(40);

    @include xs {
      width: 100%;
      margin-bottom: px(30);
    }
  }

  &__address-item__location-link {
    display: block;
    height: px(360);

    @include xs {
      height: auto;
    }
  }

  &__address-item__location__image {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__address-item__location__img {
    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: center;

    @include sm {
      object-fit: contain;
    }
  }

  &__info {
    text-align: center;
    padding: px(40) 0;
  }

  &__info-title,
  &__info-subtitle {
    font-family: "Montserrat", sans-serif;
    font-size: px(24);
    color: var(--blackLight);
    line-height: 1.5;
    font-weight: 400;

    @include xl {
      font-size: px(20);
    }

    @include sm {
      font-size: px(18);
    }

    span {
      display: block;
    }

    b {
      font-weight: 600;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__info-title {
    width: 100%;
    max-width: px(900);
    margin: 0 auto px(20);
  }

  &__info-subtitle {
    margin-bottom: px(40);
  }

  &__info-social {
    margin-bottom: px(20);
  }
}
