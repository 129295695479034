.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__logo {
    width: px(246);
    height: px(80);

    @include xl {
      width: px(158);
    }

    @include xs {
      margin-right: px(24);
    }
  }

  &__logo-link {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__logo-link__img {
    width: 100%;
    height: 100%;

    object-fit: contain;
    object-position: center;
  }

  &__address {
    display: flex;

    @include lg {
      display: none;
      width: 100%;
      max-width: px(330);
      margin-left: auto;
      padding: px(10);

      flex-direction: column;

      position: absolute;
      top: 100%;
      right: 0;
      z-index: 1;

      background-repeat: repeat;
      background-color: var(--baseWhite);
      border: px(2) solid var(--grayLight);
      box-shadow: 0px 6px 16px 0px rgba(var(--gray-medium-rgb), 0.07);
    }

    &.active {
      display: flex;
      animation: fade-down 0.3s ease-in-out normal forwards;
    }
  }

  &__address-item {
    min-width: px(301);
    font-style: normal;

    @include lg {
      text-align: right;
      margin-bottom: px(30);
      padding-right: px(10);
    }

    & + .nav__address-item {
      margin-left: px(80);

      @include xl {
        margin-left: px(30);
      }

      @include lg {
        margin-left: 0;
      }
    }
  }

  &__address-item__location {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    padding-bottom: px(4);

    border-bottom: px(1) solid var(--grayDarken);

    @include lg {
      display: flex;
    }
  }

  &__address-item__location-name {
    font-family: "Montserrat", sans-serif;
    font-size: px(20);
    color: var(--redMedium);
    font-weight: 700;
    line-height: 1.5;

    position: relative;

    @include xl {
      font-size: px(16);
    }
  }

  &__address-item__location-number {
    font-family: "Montserrat", sans-serif;
    font-size: px(16);
    line-height: 1.5;
    color: var(--grayLight);

    display: flex;

    position: relative;

    transition: opacity 0.2s linear;

    @include xl {
      font-size: px(16);
    }

    @include lg {
      color: var(--baseBlack);
    }

    &:hover {
      &::after {
        width: 100%;
      }
    }

    &::after {
      content: "";
      display: block;
      width: 0;
      height: 1px;
      background-color: var(--baseWhite);

      position: absolute;
      left: 50%;
      bottom: 0;
      z-index: 1;

      transform: translateX(-50%);
      transition: width 0.2s linear;
    }
  }

  &__address-item__location-link {
    font-family: "Montserrat", sans-serif;
    font-size: px(16);
    color: $baseWhite;
    line-height: 1.5;

    position: relative;

    @include xl {
      font-size: px(14);
    }

    @include lg {
      color: var(--blackLight);
    }

    &:hover {
      &::after {
        width: 100%;
      }
    }

    &::after {
      content: "";
      display: block;
      width: 0;
      height: 1px;
      background-color: var(--grayDarken);

      position: absolute;
      left: 50%;
      bottom: 0;
      z-index: 1;

      transform: translateX(-50%);
      transition: width 0.2s linear;
    }
  }

  &__address-btn {
    display: none;
    width: px(40);
    height: px(40);
    margin-right: px(20);
    border: px(1) solid var(--baseWhite);
    background-color: transparent;
    cursor: pointer;

    font-size: 0;

    @include lg {
      display: block;
    }

    @include xs {
      width: px(35);
      height: px(35);

      margin-right: px(10);
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__lang {
    display: flex;
    align-items: center;

    @include lg {
      text-align: right;
    }

    &.mob {
      padding-right: px(10);

      .btn--gray {
        border: px(1) solid var(--redMedium);
        background-color: transparent;

        &::before {
          background-color: rgba(var(--red-medium-rgb), 0.8);
        }
      }
    }
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    position: relative;
    @include lg {
      width: 100%;
      flex-direction: column;
      margin-bottom: px(25);
    }
  }

  &__footer-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    position: relative;
    @include lg {
      width: 100%;
      flex-direction: column;
      margin-bottom: px(25);

      padding: 0 px(20);
    }

    & li:hover .nav__footer-dropdown__wrapper {
      @include lg {
        display: none;
      }
    }
  }

  &__footer-item,
  &__footer-subnav__item {
    display: flex;
    font-family: "Montserrat", sans-serif;
    font-size: px(20);
    color: var(--blackLight);
    font-weight: 500;
    line-height: 1.5;
    cursor: pointer;

    transition: all 0.2s ease-in-out;
    position: relative;

    &:hover {
      &::after {
        width: 100%;
      }
    }

    @include xl {
      font-size: px(14);
    }

    @include lg {
      display: block;

      font-size: px(20);
      text-align: left;
    }

    & + .nav__footer-item {
      margin-left: px(60);

      @include xl {
        margin-left: px(40);
      }

      @include lg {
        margin-left: 0;
      }
    }

    &.active {
      & > .nav__footer-item__link:not(.desk)::after {
        transform: rotateZ(-135deg);

        border-bottom: px(2) solid var(--baseWhite);
        border-right: px(2) solid var(--baseWhite);
      }

      .nav__footer-item__link::before {
        background-color: inherit;
      }

      & > .nav__footer-item__link:not(.desk) {
        color: var(--baseWhite);

        background-color: var(--redMedium);
      }
    }

    &::after {
      content: "";
      display: block;
      width: 0;
      height: 1.5px;
      background-color: var(--redMedium);

      position: absolute;
      left: 50%;
      bottom: 0;
      z-index: 1;

      transform: translateX(-50%);
      transition: width 0.2s linear;

      @include lg {
        display: none;
      }
    }
  }

  &__footer-list__wrapper {
    display: none;
    border-top: px(1) solid var(--redMedium);
    border-bottom: px(1) solid var(--redMedium);

    background-color: rgba(var(--red-medium-rgb), 0.15);
  }

  &__footer-item__link,
  &__footer-subnav__item {
    display: block;
    justify-content: space-between;
    align-items: center;

    color: var(--blackLight);

    position: relative;

    @include lg {
      padding: px(20);
    }

    &:hover {
      color: var(--redMedium);
    }

    &::before {
      content: "";
      width: calc(100% - px(40));
      height: px(1);
      display: none;
      background-color: var(--gray);

      position: absolute;
      left: 50%;
      bottom: 0;

      transform: translateX(-50%);

      @include lg {
        display: block;
      }
    }

    &.arrow {
      &::after {
        content: "";
        display: inline-block;
        width: px(12);
        height: px(12);
        vertical-align: middle;
        margin-left: px(10);

        border-bottom: px(2) solid var(--redMedium);
        border-right: px(2) solid var(--redMedium);
        border-radius: 1px 2px 2px 2px/ 1px 2px 2px 2px;

        position: static;
        top: 0;
        right: 0;
        z-index: 2;

        transform: rotateZ(45deg);
        transition: all 0.2s linear;
      }
    }

    &.active {
      &::after {
        transform: rotateZ(-135deg) translateY(px(-5));
      }
    }
  }

  &__footer-dropdown__wrapper {
    padding-top: px(16);
    opacity: 0;
    visibility: hidden;

    position: absolute;
    top: 100%;
    left: 50%;
    z-index: 1;

    width: 150%;
    min-width: px(325);

    transform: translateX(-50%);

    @include lg {
      opacity: 1;
      visibility: visible;

      position: static;
      top: 0;
      left: 0;
      display: none;

      min-width: auto;
      width: auto;
      padding-top: 0;

      transform: translateX(0);
    }
  }

  &__footer-dropdown {
    box-shadow: 0px 6px 25px 0px rgba(var(--gray-medium-rgb), 0.68);
    background-color: var(--baseWhite);
    padding: px(32) 0 px(20);

    max-height: px(612);
    overflow-y: auto;

    @include lg {
      padding: 0 px(20);
      box-shadow: none;
      max-height: none;
    }
  }

  &__footer-dropdown__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: px(12) px(20);

    position: relative;

    font-family: "Montserrat", sans-serif;
    font-size: px(19);
    font-weight: 400;
    line-height: 1.5;
    text-align: left;

    @include xl {
      font-size: px(20);
    }

    @include lg {
      padding: 0;
    }

    &:hover {
      &::after {
        transform: translateX(px(10)) rotateZ(-45deg);
        opacity: 0;
      }

      & > .nav__footer-dropdown__item-link {
        color: var(--redMedium);
        font-weight: 600;

        @include lg {
          font-weight: 400;
        }
      }
    }

    &::before {
      content: "";
      width: calc(100% - px(40));
      height: px(1);
      display: block;
      background-color: var(--gray);

      position: absolute;
      left: 50%;
      bottom: 0;

      transform: translateX(-50%);
    }

    &--arrow {
      @include arrow {
        border-bottom: 3px solid var(--redMedium);
        border-right: 3px solid var(--redMedium);

        @include lg {
          display: none;
        }
      }
    }

    &.text-red {
      cursor: default;

      &::before {
        display: none;

        @include lg {
          display: block;
        }
      }

      .nav__footer-dropdown__item-link {
        color: var(--redMedium);
        text-decoration: underline;

        @include lg {
          color: var(--blackLight);

          &:hover {
            color: var(--redMedium);
          }
        }

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &__footer-dropdown__item-link {
    width: 100%;
    display: block;

    @include lg {
      text-decoration: underline;
      padding: px(20);

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__footer-dropdown__item-content {
    opacity: 0;
    visibility: hidden;
    display: block;
    padding: px(8) 0 px(20);
    min-width: px(400);
    max-height: px(400);
    overflow-y: auto;

    background-color: var(--baseWhite);
    box-shadow: 0px px(6) px(25) 0px rgba(var(--gray-medium-rgb), 0.68);

    position: fixed;
    top: 0;
    z-index: -1;

    @include lg {
      display: none;
    }

    li:last-child {
      border-bottom-color: transparent;
    }

    &.overflow {
      top: auto;
      bottom: 0;
    }
  }
}
