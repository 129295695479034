.profit {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: px(1) solid var(--gray);

  &__photo {
    width: 46.87%;
    margin-bottom: px(60);

    @include lg {
      width: calc(100% + px(30));
      margin-inline: px(-15);
    }
  }

  &__photo-picture__image {
    display: block;
    width: 100%;
    height: px(720);

    @include lg {
      height: px(400);
    }

    @include sm {
      height: auto;
    }
  }

  &__photo-picture__img {
    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: top center;
  }

  &__content {
    width: 53.13%;
    margin-bottom: px(60);
    padding-left: px(100);

    @include xl {
      padding-left: px(60);
    }

    @include lg {
      width: 100%;
      padding-left: 0;

      margin-bottom: px(40);
    }

    @include xs {
      font-size: px(20);

      margin-bottom: px(20);
    }
  }

  &__content-title {
    font-family: "Montserrat", sans-serif;
    font-size: px(32);
    color: var(--blackLight);
    font-weight: 700;
    line-height: 1.5;
    text-transform: uppercase;
    text-align: center;

    width: 100%;
    max-width: px(518);
    margin: 0 auto px(80);

    @include xl {
      font-size: px(28);
    }

    @include md {
      font-size: px(20);

      margin: 0 auto px(60);
    }

    @include xs {
      margin: 0 auto px(40);
    }
  }

  &__content-list {
    & li {
      padding-left: px(40);
      margin-bottom: px(20);

      position: relative;

      &::before {
        content: "";
        display: block;
        width: px(20);
        height: px(20);
        background-color: var(--redMedium);

        position: absolute;
        top: px(3);
        left: 0;
        z-index: 1;
      }

      & div:first-child {
        font-family: "Montserrat", sans-serif;
        font-size: px(20);
        color: var(--blackLight);
        font-weight: 600;
        line-height: 1.3;

        margin-bottom: px(12);

        @include xl {
          font-size: px(18);
        }

        @include xs {
          font-size: px(16);
        }
      }

      & div:last-child {
        font-family: "Montserrat", sans-serif;
        font-size: px(18);
        color: var(--blackLight);
        line-height: 1.5;

        @include xl {
          font-size: px(16);
        }

        @include xs {
          font-size: px(14);
        }

        p {
          margin-bottom: px(25);
        }
      }
    }
  }
}
