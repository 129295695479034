:root {
  // Colors
  --baseWhite: #{$baseWhite};
  --baseBlack: #{$baseBlack};
  --baseText: #{$blackLight};

  --baseWhite-rgb: 255, 255, 255;
  --baseBlack-rgb: 0, 0, 0;
  --gray-rgb: 146, 146, 146;
  --gray-medium-rgb: 52, 52, 52;
  --gray-light-rgb: 254, 254, 254;
  --gray-dark-rgb: 48, 48, 48;
  --gray-bold-rgb: 20, 20, 20;
  --red-medium-rgb: 153, 22, 30;
  --green-rgb: 18, 130, 9;
  --yellow-rgb: 220, 199, 10;
  --orange-rgb: 206, 135, 30;
  --blue-rgb: 38, 89, 221;
  --pink-rgb: 217, 58, 182;
  --turquoise-rgb: 23, 186, 208;

  --blackMedium: #{$blackMedium};
  --blackLight: #{$blackLight};
  --blackDarken: #{$blackDarken};

  --redMedium: #{$redMedium};
  --redLight: #{$redLight};
  --redDark: #{$redDark};

  --green: #{$green};

  --yellow: #{$yellow};

  --orange: #{$orange};

  --pink: #{$pink};

  --turquoise: #{$turquoise};

  --gray: #{$gray};
  --grayLight: #{$grayLight};
  --grayMedium: #{$grayMedium};
  --grayDarken: #{$grayDarken};
  --grayBold: #{$grayBold};
  --grayExtraBold: #{$grayExtraBold};

  --blue: #{$blue};
  --blueBlack: #{$blueBlack};

  // Sizes
  --header-height: #{px(171)};
  --current-scrollbar-width: 0px;
}
