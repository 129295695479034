.services {
  &__inner {
    display: flex;
    flex-wrap: wrap;
    margin-inline: px(-10);
    padding-bottom: px(50);
  }

  &__col {
    width: 50%;
    padding: px(10);

    @include md {
      width: 100%;
      max-width: px(600);

      margin: 0 auto;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: px(40);

    background-color: var(--baseWhite);

    box-shadow: 0px 6px 16px 0px rgba(var(--gray-medium-rgb), 0.07);

    @include xs {
      padding: px(30);
    }

    & .btn.active {
      &::after {
        transform: translate3d(-45%, -25%, 0) rotate(-135deg);

        border-right-color: var(--redMedium);
        border-bottom-color: var(--redMedium);
      }

      background-color: var(--baseWhite);
    }
  }

  &__item-title {
    font-family: "Montserrat", sans-serif;
    font-size: px(20);
    color: var(--blackLight);
    line-height: 1.5;
    font-weight: 700;

    padding-right: px(20);

    @include hide-text {
      -webkit-line-clamp: 2;
      line-clamp: 2;
    }

    @include xs {
      font-size: px(18);
    }
  }

  &__tabs {
    padding: px(80) 0;
    background-color: var(--baseWhite);

    position: relative;

    &::before,
    &::after {
      content: "";
      display: block;
      width: 100vw;
      height: 100%;
      background-color: var(--baseWhite);

      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }

    &::after {
      left: -100vw;
    }

    @include md {
      padding: px(50) 0;
    }
  }

  &__content {
    display: none;
    opacity: 0;

    .contents:first-child {
      padding-top: 0;
    }

    .contents:last-child {
      padding-bottom: 0;
    }

    .contents {
      padding: px(30) 0;
      margin: 0 auto;

      table {
        margin: 0 auto;
      }
    }

    &.active {
      display: block;

      animation: fade-on 0.6s ease-in-out forwards;
    }
  }

  &__content-item {
    display: none;
  }

  &__content-header {
    margin: px(40) 0px px(60);
  }
}
