.main-title {
  text-align: center;
}

.section {
  &__title > b {
    color: var(--redMedium);
  }
}

.block-title {
}
