.footer {
  padding: px(100) 0 px(60);
  padding-top: 80px;
  background-color: var((--blackLight));

  @include lg {
    padding: px(65) 0 px(35);
  }

  @include xs {
    padding: px(60) 0 px(30);
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-bottom: px(1) solid var(--blueBlack);

    @include lg {
      flex-direction: column;
    }
  }

  &__logo-link {
    width: px(318);
    height: px(130);

    @include xl {
      width: px(258);
      height: px(110);
    }

    @include sm {
      width: px(218);
      height: px(90);
    }
  }

  &__logo-link__img {
    width: 100%;
    height: 100%;

    object-fit: contain;
    object-position: center;
  }

  &__block {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: px(60);

    @include lg {
      margin-bottom: px(40);
    }

    @include sm {
      flex-direction: column;
      text-align: center;
    }
  }

  &__nav-list {
    padding-inline: px(115);

    @include lg {
      margin-bottom: px(30);
    }

    @include xl {
      padding-inline: px(30);
    }

    @include sm {
      margin-bottom: px(20);
    }
  }

  &__nav-list__item {
    margin-bottom: px(20);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__nav-list__item-link {
    font-family: "Montserrat", sans-serif;
    font-size: px(20);
    color: var(--grayLight);
    line-height: 1.5;

    transition: color 0.2s linear;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 0;
      height: 1px;
      background-color: var(--redMedium);

      position: absolute;
      left: 50%;
      bottom: 0;
      z-index: 1;

      transform: translateX(-50%);
      transition: width 0.2s linear;
    }

    &:hover {
      color: var(--redMedium);

      &::after {
        width: 100%;
      }
    }

    &:focus {
      color: var(--redMedium);
    }
  }

  &__social {
    display: flex;
    margin-top: px(20);

    @include xs {
      justify-content: center;
    }
  }

  &__social-link {
    display: block;
    width: px(60);
    height: px(60);

    transition: opacity 0.2s linear;

    @include lg {
      width: px(40);
      height: px(40);
    }

    & + .footer__social-link {
      margin-left: px(20);
    }

    &:hover {
      opacity: 0.8;
    }

    &:focus {
      opacity: 0.8;
    }
  }

  &__social-link__icon {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__address {
    display: flex;
    flex-wrap: wrap;
    padding-top: px(60);
    padding-bottom: px(40);

    margin-inline: px(-40);

    @include xl {
      margin-inline: px(-30);
    }

    @include lg {
      justify-content: center;
    }

    @include xs {
      padding-top: px(40);
    }
  }

  &__address-item {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    padding: 0 px(40);

    @include xl {
      padding: 0 px(30);
    }

    @include lg {
      width: 100%;
      max-width: px(800);
    }

    @include xs {
      text-align: center;
    }

    @include sm {
      align-items: center;
    }
  }

  &__address-item__info {
    width: 37.63%;
    padding-right: px(40);
    margin-bottom: px(30);

    @include xl {
      width: 44.63%;
      padding-right: px(40);
    }

    @include sm {
      padding-right: px(27);
    }

    @include xs {
      width: 100%;
      padding-right: 0;
    }
  }

  &__address-item__info-title {
    font-family: "Montserrat", sans-serif;
    font-size: px(20);
    color: var(--redMedium);
    font-weight: 500;
    line-height: 1.5;
    text-transform: uppercase;

    margin-bottom: px(20);
  }

  &__address-item__info-list__item {
    display: flex;
    align-items: center;

    margin-bottom: px(12);

    @include xs {
      justify-content: center;
    }
  }

  &__address-item__info-list__item-link {
    font-family: "Montserrat", sans-serif;
    font-size: px(18);
    color: var(--grayLight);
    margin-left: px(12);
    line-height: 1.5;

    &:hover {
      text-decoration: underline;
    }

    &--address {
      &:hover {
        text-decoration: none;
      }

      text-decoration: none;
    }

    &--time {
      &:hover {
        text-decoration: none;
      }
    }

    &--mail {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__address-item__location {
    width: 62.37%;
    margin-bottom: px(30);
    height: auto;

    @include xl {
      width: 55.37%;
      height: px(300);
    }

    @include xs {
      width: 100%;
      height: px(300);
    }
  }

  &__copy {
    padding: px(5) 0;
    background-color: rgba(var(--baseWhite-rgb), 10%);
  }

  &__copy-inner {
    display: flex;
    justify-content: space-between;

    @include xs {
      flex-direction: column;
      align-items: center;
    }
  }

  &__copy-item {
    font-family: "Montserrat", sans-serif;
    font-size: px(20);
    color: var(--baseWhite);
    font-weight: 500;
    line-height: 1.5;

    @include lg {
      font-size: px(18);
    }

    @include xs {
      font-size: px(16);
    }
  }
}
