.instruction {
  &__item {
    width: 100%;
    max-width: px(1060);
    margin: 0 auto px(56);
    padding: px(40);

    box-shadow: 0 px(4) px(40) 0 rgba(var(--gray-bold-rgb), 0.07);
    background-color: var(--grayLight);

    position: relative;

    @include lg {
      padding: px(30);
    }

    @include xs {
      padding: px(20);
    }

    @include arrow {
      width: px(20);
      height: px(20);

      position: absolute;
      bottom: px(-35);
      left: 50%;

      border-right-color: var(--redMedium);
      border-bottom-color: var(--redMedium);

      transform: rotateZ(45deg) translateX(-50%);
    }

    &:last-child {
      margin-bottom: px(60);

      &::after {
        display: none;
      }
    }

    & > ul {
      font-family: "Montserrat", sans-serif;
      font-size: px(18);
      color: var(--blackLight);
      line-height: 1.5;

      @include xl {
        font-size: px(16);
      }

      @include xs {
        font-size: px(14);
      }

      & > li {
        margin-bottom: px(25);

        list-style: decimal;
        list-style-position: inside;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__item-header {
    display: flex;
    align-items: center;

    margin-bottom: px(20);
  }

  &__item-header__number {
    width: px(50);
    height: px(50);
    border: px(2) solid var(--redMedium);

    display: flex;
    justify-content: center;
    align-items: center;

    font-family: "Montserrat", sans-serif;
    font-size: px(24);
    color: var(--redMedium);
    font-weight: 700;
    line-height: 1.5;

    @include lg {
      width: px(40);
      height: px(40);

      font-size: px(20);
    }

    @include sm {
      font-size: px(18);

      width: px(30);
      height: px(30);
    }
  }

  &__item-header__title {
    margin-left: px(20);

    font-family: "Montserrat", sans-serif;
    font-size: px(24);
    color: var(--blackLight);
    font-weight: 600;
    line-height: 1.3;

    @include xl {
      font-size: px(22);
    }

    @include lg {
      font-size: px(20);
    }

    @include sm {
      font-size: px(18);
    }

    @include xs {
      font-size: px(16);
    }
  }

  & + .text-center {
    padding-bottom: px(70);
  }
}
