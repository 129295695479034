@mixin font($font_name, $file_name, $weight, $style) {
  @font-face {
    font-family: $font_name;
    font-display: swap;
    src: url('../fonts/#{$file_name}.eot') format('eot');
    src: url('../fonts/#{$file_name}.woff') format('woff'), url('../fonts/#{$file_name}.woff2') format('woff2'),
      url('../fonts/#{$file_name}.svg') format('svg'), url('../fonts/#{$file_name}.ttf') format('ttf');
    font-weight: #{$weight};
    font-style: #{$style};
  }
}

@mixin media-down($value) {
  @media screen and (max-width: $value + 'px') {
    @content;
  }
}

@mixin media-up($value) {
  @media screen and (min-width: $value + 'px') {
    @content;
  }
}

@mixin media-between($value1, $value2) {
  @media screen and (min-width: $value1 + 'px') and (max-width: $value2 + 'px') {
    @content;
  }
}
