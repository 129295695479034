.select {
  width: fit-content;
  min-width: px(150);
  max-width: px(200);
  position: relative;
  margin: 0 auto;

  &--lang {
    display: inline-block;
    max-width: px(64);
    min-width: auto;

    margin-left: px(80);

    @include xl {
      margin-left: px(30);
    }

    @include lg {
      margin-left: 0;
      margin-right: px(20);
    }

    @include xs {
      margin-right: px(10);
    }

    .select__trigger {
      padding: 0;
      border: none;
      background-color: transparent;

      &:focus-within {
        box-shadow: none;
      }

      &:hover {
        .select__trigger-value {
          font-weight: 500;
        }
      }
    }

    .select__trigger-value {
      font-family: "Montserrat", sans-serif;
      font-size: px(24);
      color: var(--grayLight);
      font-weight: 400;
      text-transform: uppercase;

      @include xs {
        font-size: px(20);
      }
    }

    .select__dropdown {
      min-width: px(120);
      padding: px(20);
      height: auto;
      border: none;
    }

    .select__item {
      border-bottom: px(1) solid #e5e5e5;
    }

    .select__btn {
      font-family: "Montserrat", sans-serif;
      font-size: px(19);
      color: var(--blackLight);
      line-height: 1.5;
      text-transform: uppercase;

      padding: px(7) 0 px(4) 0;

      &:hover {
        background-color: transparent;
        color: var(--redMedium);
      }
    }
  }

  &--nav {
    min-width: auto;
    max-width: none;

    @include lg {
      width: px(200);
    }

    .select__trigger {
      display: none;
      border: px(1) solid var(--redMedium);
      background-color: var(--baseWhite);

      @include lg {
        display: flex;
      }

      &:focus-within {
        box-shadow: none;
      }

      &:hover {
        background-color: var(--redMedium);

        .select__trigger-value {
          color: var(--baseWhite);
        }

        .icon {
          background-color: var(--baseWhite);
        }
      }
    }

    .select__trigger-value {
      font-family: "Montserrat", sans-serif;
      font-size: px(20);
      color: var(--redMedium);
      font-weight: 700;
      line-height: 1.5;
      text-transform: uppercase;
      vertical-align: top;
    }

    .select__dropdown {
      height: auto;

      pointer-events: visible;
      visibility: visible;
      opacity: 1;

      border: px(1) solid transparent;
      background-color: var(--baseWhite);

      position: static;

      transform: none;

      @include lg {
        height: px(200);
        border-color: var(--redMedium);
        opacity: 0;
        pointer-events: none;
        visibility: hidden;

        position: absolute;
        left: 0;

        transform: translateY(px(-30));
      }
    }

    .select__list {
      display: flex;
      flex-wrap: wrap;

      @include lg {
        display: block;
      }
    }

    .select__item {
      & + .select__item {
        margin-left: px(20);

        @include xl {
          margin-left: px(10);
        }

        @include lg {
          margin-left: 0;
          width: 100%;
        }
      }

      margin-bottom: px(20);

      @include lg {
        margin-bottom: 0;
      }
    }

    .select__link {
      padding: px(18) px(40);
      background-color: transparent;

      font-family: "Montserrat", sans-serif;
      font-size: px(16);
      color: var(--redMedium);
      font-weight: 700;
      line-height: 1.5;

      text-align: center;
      text-transform: uppercase;
      vertical-align: top;

      @include lg {
        padding: px(8) px(15);
        text-align: start;
      }

      &:hover {
        background-color: var(--redMedium);
        color: var(--baseWhite);
      }

      &.active {
        border: px(1) solid var(--redMedium);
      }
    }
  }

  &--calculate,
  &--tabs,
  &--service {
    width: 100%;
    min-width: auto;
    max-width: none;
    margin: 0;

    .select__trigger {
      padding: px(21) px(20);
      border: px(1) solid var(--gray);

      font-family: "Montserrat", sans-serif;
      font-size: px(16);
      color: var(--grayExtraBold);
      line-height: 1.5;

      @include md {
        padding: px(15) px(20);
      }

      &:focus-within {
        box-shadow: none;
        border-color: var(--redMedium);
      }
    }

    .icon {
      width: px(20);
      height: px(20);
    }

    .select__dropdown {
      left: 0;
      height: auto;
      border: px(1) solid var(--gray);
    }

    .select__btn {
      font-family: "Montserrat", sans-serif;
      font-size: px(16);
      color: var(--blackLight);
      font-weight: 600;

      padding: px(10) px(10);

      &:hover {
        background-color: var(--redMedium);
      }
    }
  }

  &--service {
    .select__trigger {
      padding: px(12) px(20);
      border: px(1) solid var(--gray);

      font-family: "Montserrat", sans-serif;
      font-size: px(16);
      color: var(--grayExtraBold);
      line-height: 1.5;
    }
  }

  &--tabs {
    max-width: px(450);
    margin: 0 auto;

    &.active .select__trigger {
      background-color: var(--redMedium);

      color: var(--baseWhite);
      .icon {
        background-color: var(--baseWhite);
      }
    }

    .select__trigger {
      border: px(1) solid var(--redMedium);
      color: var(--redMedium);
      font-weight: 600;

      &:hover {
        background-color: var(--redMedium);
        color: var(--baseWhite);

        .icon {
          background-color: var(--baseWhite);
        }
      }
    }

    .select__btn {
      color: var(--redMedium);
    }
  }

  &__error {
    display: none;
    margin-top: 5px;
    width: 100%;

    font-family: 'Montserrat', sans-serif;
    font-size: px(16);
    color: red;
    line-height: 1.5;
    
    @include sm {
      font-size: px(14);
    }
  }

  &.invalid .select__error {
    display: block;
  }

  &.open-top {
    .select__dropdown {
      top: unset;
      bottom: calc(100% + px(10));
      transform: translateY(px(30));
    }
  }

  &.active {
    z-index: 100;

    .select__trigger {
      .icon-arrow-select {
        transform: rotate(-180deg);
      }
    }

    .select__dropdown {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
      transform: translateY(0);
    }

    .select__dropdown {
      border-color: var(--redMedium);
    }
  }

  &__trigger {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: px(5) px(15);
    text-align: start;
    position: relative;
    z-index: 99;
    border: px(1) solid #000;
    background-color: var(--baseWhite);
    cursor: pointer;
    transition: all 0.3s ease;

    &:focus-within {
      box-shadow: 0 0 0 px(5) rgba($baseBlack, 0.2);
    }

    &-value {
      display: block;
      width: 100%;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .icon {
      margin-left: px(10);

      @include sm {
        margin-left: px(5);
      }
    }
  }

  &__dropdown {
    width: 100%;
    height: px(150);
    max-height: px(300);

    position: absolute;
    top: calc(100% + px(10));
    left: 0;
    z-index: 2;

    background-color: var(--baseWhite);
    border: px(1) solid #000;

    opacity: 0;
    overflow: auto;
    visibility: hidden;
    pointer-events: none;

    transition: all 0.3s ease;
    transform: translateY(px(-30));

    @include xxl {
      left: px(-50);
    }
  }

  &__list {
    max-height: 100%;
    overflow-y: auto;
  }

  &__link,
  &__btn {
    display: block;
    width: 100%;
    padding: px(4) px(10);
    text-align: start;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background: var(--baseBlack);
      color: var(--baseWhite);
    }
  }
}
