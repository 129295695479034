.faq {
  width: 100%;
  max-width: px(1060);
  margin: 0 auto;
  padding-bottom: px(70);

  @include md {
    padding-bottom: px(50);
  }

  @include xs {
    padding-bottom: px(30);
  }

  &__col {
    width: 100%;

    margin-bottom: px(20);
  }

  &__item {
    padding: px(40);
    background-color: var(--baseWhite);
    box-shadow: 0px 6px 18px 0px rgba(var(--gray-medium-rgb), 0.07);

    border: px(1) solid var(--gray);

    @include xl {
      padding: px(30);
    }

    @include sm {
      padding: px(15);
    }

    &.active {
      .faq__item-header {
        padding-bottom: px(40);
        border-bottom: px(1) solid var(--gray);

        @include xl {
          padding-bottom: px(35);
        }

        @include md {
          padding-bottom: px(30);
        }

        @include sm {
          padding-bottom: px(15);
        }
      }

      .btn {
        background-color: transparent;

        &::after {
          border-right-color: var(--redMedium);
          border-bottom-color: var(--redMedium);

          transform: translate3d(-45%, -10%, 0) rotate(-135deg);
        }
      }
    }
  }

  &__item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    transition: padding 0.4s linear;
  }

  &__item-content {
    padding-top: px(40);

    @include sm {
      padding-top: px(20);
    }

    &.hide {
      display: none;
    }
  }

  &__item-header-content__title {
    font-family: "Montserrat", sans-serif;
    font-size: px(24);
    color: var(--blackLight);
    font-weight: 600;
    line-height: 1.3;

    padding-right: px(15);

    @include xl {
      font-size: px(20);
    }

    @include sm {
      font-size: px(18);
    }
  }

  &__item-header-content__text {
    font-family: "Montserrat", sans-serif;
    font-size: px(18);
    color: var(--blackLight);
    line-height: 1.5;

    padding-right: px(20);

    @include hide-text {
      line-clamp: 2;
      -webkit-line-clamp: 2;
    }

    @include xs {
      font-size: px(14);

      @include hide-text {
        line-clamp: unset;
        -webkit-line-clamp: unset;
      }
    }
  }

  &__item-content__accordion-content__text {
    font-family: "Montserrat", sans-serif;
    font-size: px(18);
    color: var(--blackLight);
    line-height: 1.3;

    p:not(:last-child) {
      margin-bottom: px(30);
    }
  }

  &__item-content__accordion-content__list {
    margin-bottom: px(20);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item-content__accordion-content__list-header,
  &__item-content__accordion-content__list-item {
    font-family: "Montserrat", sans-serif;
    font-size: px(18);
    color: var(--blackLight);
    line-height: 1.5;

    position: relative;

    padding-left: px(25);
    margin-bottom: px(12);

    @include xl {
      font-size: px(16);
    }

    @include sm {
      font-size: px(14);
    }

    @include xs {
      font-size: px(12);
    }

    &::before {
      content: "";
      display: block;
      width: 13px;
      height: 13px;
      position: absolute;
      top: 5px;
      left: 0;
      z-index: 1;
      background-color: var(--redMedium);

      @include sm {
        width: px(10);
        height: px(10);
      }
    }
  }

  &__item-content__accordion-content__list-item {
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item-content__accordion-content__list-header {
    padding-left: 0;

    &::before {
      display: none;
    }
  }
}
