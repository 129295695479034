$baseWhite: #fff;
$baseBlack: #000;

$blackLight: #212529;
$blackMedium: #343434;
$blackDarken: #2F2F2F;

$redMedium: #99161e;
$redLight: #ff271a;
$redDark: #662529;

$green: #128209;

$yellow: #dcc70a;

$orange: #ce7d1e;

$pink: #d93ab6;

$turquoise: #17bad0;

$gray: #efefef;
$grayLight: #fefefe;
$grayMedium: #fffcf8;
$grayDarken: #8a8a8a;
$grayExtraBold: #9f9f9f;
$grayBold: #929292;

$blue: #2659dd;
$blueBlack: #393d42;
