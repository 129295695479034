.mob {
  display: none;

  @include lg {
    display: block;
  }

  &-hide {
    @include lg {
      display: none;
    }
  }
}

.mob-f {
  display: none;

  @include lg {
    display: flex;
  }

  &-hide {
    @include lg {
      display: none;
    }
  }
}

.desk {
  display: block;

  @include lg {
    display: none;
  }
}

.show {
  display: block;
}

.gray-page {
  background-color: var(--gray);
}
