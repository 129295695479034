.error {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  position: relative;
  z-index: 1;
  height: calc(100vh - var(--header-height));

  &__title {
    font-family: "Montserrat", sans-serif;
    font-size: px(180);
    color: var(--redMedium);
    font-weight: 500;
    line-height: 1.5;

    @include xl {
      font-size: px(120);
    }

    @include lg {
      font-size: px(80);
    }
  }

  &__content {
    margin-bottom: px(60);
  }

  &__content-text {
    font-family: "Montserrat", sans-serif;
    font-size: px(20);
    color: var(--blackLight);
    line-height: 1.5;

    @include xl {
      font-size: px(18);
    }

    p {
      margin-bottom: px(8);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    width: 100%;
    height: 100%;
  }

  &__bg-photo {
    width: 100%;
    height: 100%;

    &__picture__image {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__bg-photo__picture__img {
    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: center;
  }
}
