.modal {
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y: auto;

  background-color: rgba($baseBlack, 0.78);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;

  padding: px(20);

  &.show {
    display: flex;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }

  &__dialog {
    max-width: px(1060);
    width: 100%;
    margin: auto;

    position: relative;
    background-color: $baseWhite;
  }

  &__header {
    display: flex;
    justify-content: center;
    padding: px(40) px(40) 0 px(40);


  }

  &__header-title {
    font-family: "Montserrat", sans-serif;
    font-size: px(24);
    color: var(--blackDarken);
    font-weight: 600;
    line-height: 1.5;
    text-align: center;

    @include sm {
      font-size: px(16);
    }
  }

  &__header-close-btn {
    width: px(40);
    height: px(40);

    position: absolute;
    top: px(40);
    right: px(40);
    z-index: 1;

    padding: 0;
    width: px(30);
    min-width: px(30);
    height: px(30);

    .icon-close {
      width: px(46);
      height: px(46);

      @include sm {
        width: px(30);
      height: px(30);
      }
    }

    @include sm {
      top: px(15);
      right: px(15);
    }
  }

  &__body {
    padding: px(40);

    @include xs {
      padding: px(20);
    }
  }
}
