.order {
  &__form {
    b {
      font-weight: 400;
      color: var(--redMedium);
    }

    .form__row {
      padding: 0;
    }

    .form__col {
      padding: 0 px(10);
      margin-bottom: px(12);

      @include md {
        flex: 0 0 100%;
      }

      &-sm {
        padding: 0 px(10) 0 px(10);

        @include md {
          padding: 0 px(10) 0 px(10);
        }
      }
    }

    .select__trigger {
      border: px(1) solid var(--grayDarken);
    }

    .form-field__title {
      font-family: "Montserrat", sans-serif;
      font-size: px(16);
      color: var(--blackLight);
      line-height: 1.5;

      margin-bottom: px(8);

      @include sm {
        font-size: px(14);
      }
    }
  }

  &__input,
  &__input-textarea {
    .form-field__input,
    .form-field__textarea {
      font-family: "Montserrat", sans-serif;
      font-size: px(16);
      color: var(--blackLight);
      line-height: 1.5;

      padding: px(12) px(20);
      border: px(1) solid var(--grayDarken);

      &::placeholder {
        color: var(--grayExtraBold);
      }

      &:focus {
        border-color: var(--redMedium);
      }

      @include sm {
        font-size: px(14);
      }
    }
  }

  &__input-file {
    .form-field__input {
      opacity: 0;
      visibility: hidden;
      padding: 0;

      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }

  &__input-file__wrapper {
    background-image: linear-gradient(to left, var(--grayDarken) 50%, transparent 50%, transparent 100%),
      linear-gradient(to left, var(--grayDarken) 50%, transparent 50%, transparent 100%),
      linear-gradient(to bottom, var(--grayDarken) 50%, transparent 50%, transparent 100%),
      linear-gradient(to bottom, var(--grayDarken) 50%, transparent 50%, transparent 100%);
    background-position: top left, bottom left, top left, top right;
    background-size: px(15) px(1), px(15) px(1), px(1) px(15), px(1) px(15);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;

    padding: px(20);

    position: relative;

    transition: background-image 0.2s linear;

    &.highlight {
      background-image: linear-gradient(to left, var(--redMedium) 50%, transparent 50%, transparent 100%),
        linear-gradient(to left, var(--redMedium) 50%, transparent 50%, transparent 100%),
        linear-gradient(to bottom, var(--redMedium) 50%, transparent 50%, transparent 100%),
        linear-gradient(to bottom, var(--redMedium) 50%, transparent 50%, transparent 100%);
    }
  }

  &__input-file__wrapper-header {
    margin-bottom: px(12);
  }

  &__input-file__wrapper-text {
    font-family: "Montserrat", sans-serif;
    font-size: px(16);
    color: var(--grayExtraBold);
    line-height: 1.5;

    @include sm {
      font-size: px(14);
    }
  }

  &__input-file__wrapper-link {
    font-family: "Montserrat", sans-serif;
    font-size: px(16);
    color: var(--redMedium);
    line-height: 1.5;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }

    @include sm {
      font-size: px(14);
    }
  }

  &__input-file__wrapper-description {
    font-family: "Montserrat", sans-serif;
    font-size: px(12);
    color: var(--grayExtraBold);
    line-height: 1.28;
  }

  &__input-file__wrapper-error {
    font-family: "Montserrat", sans-serif;
    font-size: px(18);
    color: red;
    line-height: 1.5;

    @include sm {
      font-size: px(14);
    }
  }

  &__files {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: px(12);

    @include sm {
      flex-direction: column;
      margin-bottom: px(20);
    }
  }

  &__files-count {
    width: 8.98%;
    min-width: px(80);

    display: flex;
    align-items: center;

    @include sm {
      margin-top: px(10);
      margin-bottom: px(20);
    }
  }

  &__files-count__number-text {
    font-family: "Montserrat", sans-serif;
    font-size: px(14);
    color: var(--grayExtraBold);
    line-height: 1.5;

    margin-left: px(8);
  }

  &__files-content {
    display: flex;
    flex-wrap: wrap;
    width: 91.02%;
    min-height: px(66);
    margin: 0 px(-12);

    @include sm {
      min-height: auto;
    }
  }

  &__files-content-col {
    width: 33.33333%;
    padding: px(6);

    @include sm {
      width: 50%;
    }

    @include xs {
      width: 100%;
    }
  }

  &__files-content__item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border: px(1) solid var(--gray);
    box-shadow: 0 0 px(14) 0 rgba(var(--gray-medium-rgb), 0.07);

    padding: px(8) px(12);
  }

  &__files-content__item-text {
    font-family: "Montserrat", sans-serif;
    font-size: px(12);
    color: var(--grayExtraBold);
    line-height: 1.5;

    @include hide-text {
      -webkit-line-clamp: 2;
      line-clamp: 2;
    }
  }

  &__files-content__item-btn {
    background-color: none;
    border: 0;
    font-size: 0;
    cursor: pointer;
  }

  &__checkbox {
    margin-bottom: px(44);

    .form-field__col {
      font-family: "Montserrat", sans-serif;
      font-size: px(16);
      color: var(--blackLight);
      line-height: 1.5;

      @include sm {
        font-size: px(14);
      }
    }

    .form-field__row {
      display: flex;
      align-items: center;
    }

    .form-field__col {
      cursor: pointer;
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &--checkbox {
    border-color: var(--grayDarken);
    opacity: 1;
  }
}
